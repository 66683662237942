import React from 'react';
import { Text } from '@mantine/core';
import { updateDomain } from './utils';
import { useGLGOParams } from 'hooks';
import CustomModal from '../../../CustomModal';

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentDomain: string;
  setCurrentDomain: React.Dispatch<React.SetStateAction<string>>;
  newDomain: string;
  setEditDomain: React.Dispatch<React.SetStateAction<boolean>>;
  resetForm: (data?: Record<string, string>) => void;
  setOriginName: React.Dispatch<React.SetStateAction<string>>;
};
const ConfirmEditDomainModal = ({
  showModal,
  setShowModal,
  currentDomain,
  setCurrentDomain,
  newDomain,
  setEditDomain,
  resetForm,
  setOriginName,
}: Props) => {
  const { projectKey = '' } = useGLGOParams();

  return (
    <CustomModal
      size="lg"
      title="Confirmation"
      opened={showModal}
      onClose={() => setShowModal(false)}
      footerActions={[
        { label: 'Cancel', action: () => setShowModal(false) },
        {
          label: 'Confirm',
          action: () => {
            updateDomain('origin_name', newDomain, [], projectKey).then(
              (response) => {
                const { project: { origin_name: originName = '' } = {} } =
                  response || {};
                if (originName) {
                  setOriginName(originName);
                  setCurrentDomain(originName);
                  setShowModal(false);
                  setEditDomain(false);
                  resetForm({ domain: originName });
                }
              }
            );
          },
        },
      ]}
    >
      <Text ta="center" fz="1.25rem">
        You are about to change your project’s source domain from{' '}
        <Text fz="1.25rem" fw={600} span>
          {currentDomain}
        </Text>
        &nbsp;to&nbsp;
        <Text fz="1.25rem" fw={600} span>
          {newDomain}
        </Text>
        , please confirm that this is what you want to do.
      </Text>
    </CustomModal>
  );
};

export default ConfirmEditDomainModal;
