import { Box, Button, Flex, Text, Image, useMantineTheme } from '@mantine/core';
import { useSignin, shouldSkipSSO } from 'auth';
import { dashboardRC } from 'helpers';
import GLGOLogoColor from 'images/logos/glgo-logo-color.svg';

export const DesktopLogin = ({ redirectUrl }: { redirectUrl?: string }) => {
  const colors = useMantineTheme().colors;
  const skipSsoMode = shouldSkipSSO();

  const signin = useSignin(redirectUrl || dashboardRC.PAGE_ROOT);

  return (
    <>
      <Box style={{ height: 40, WebkitAppRegion: 'drag' }}></Box>
      <Flex
        w="100%"
        mih={550}
        direction="column"
        justify="center"
        align="center"
        rowGap="3rem"
        px="1rem"
      >
        <Image
          src={GLGOLogoColor}
          width={280}
          alt="GlobalLinkWeb logo"
          style={{
            objectFit: 'cover',
            width: '17.5rem',
            height: ' auto',
          }}
        />
        <Flex
          w="100%"
          maw="24rem"
          align="center"
          direction="column"
          rowGap="1.5rem"
        >
          <Button
            h={55}
            w="100%"
            bg="transparent"
            style={{
              border: `2px solid ${colors.border2[8]}`,
              borderRadius: 30,
            }}
            onClick={signin}
          >
            <Text fz="1rem" fw={600} c="text.2">
              {skipSsoMode ? 'Log in (skip SSO)' : 'Log in with browser.'}
            </Text>
          </Button>
          {!skipSsoMode && <Text size="md">You will be redirected here.</Text>}
        </Flex>
      </Flex>
    </>
  );
};
