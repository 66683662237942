import React from 'react';
import { Badge, Flex, Text } from '@mantine/core';
import { Subscription } from 'store';
import getPrettyDate from 'helpers/utils/getPrettyDate';
import classes from './CurrentPlan.module.css';

type Props = {
  sub: Subscription;
  isExpired: boolean;
};

function CurrentPlan({ sub, isExpired }: Props) {
  const prettyDate = getPrettyDate(sub.next_renewal_date);

  return (
    <Flex
      className={classes.currentPlanWrapper}
      w="100%"
      maw="none"
      p="3rem 2rem"
    >
      {isExpired ? (
        <Flex
          gap={15}
          direction={{ base: 'column', md: 'row' }}
          justify={{ base: 'start', md: 'space-between' }}
          ta="start"
          w="100%"
        >
          <Flex direction="column" gap={10}>
            <Text fw={600} size="1.1rem" c="text.3">
              {sub.price === 0 ? 'Free Trial' : sub.sku_name || 'N/A'}
            </Text>
            {sub.price === 0 && <Text>Your free trial has expired.</Text>}
          </Flex>

          <Flex
            direction="column"
            gap={10}
            align={{ base: 'start', md: 'end' }}
          >
            <Badge
              color="badge.0"
              styles={({ colors }) => ({ label: { color: colors.text[3] } })}
              classNames={{
                root: `${classes.subBadge} ${classes.canceledSubBadge}`,
              }}
            >
              I<span style={{ textTransform: 'lowercase' }}>nactive</span>
            </Badge>
            <Text c="text.4" fw={600}>
              Expired: {prettyDate}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex
          columnGap={15}
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'start', md: 'center' }}
          justify={{ base: 'start', md: 'space-between' }}
          ta="start"
          w="100%"
        >
          <Flex
            direction="column"
            justify={sub.subscription_type === 'MONTHLY' ? 'end' : 'center'}
          >
            <Text fw={500} mb="0.25rem" size="1.25rem">
              {sub.sku_name || 'N/A'}
            </Text>
            {!sub.is_canceled ? (
              sub.subscription_type === 'MONTHLY' ? (
                <Text c="text2.4" fs="italic">
                  Switch to Yearly and get 2 months <strong>FREE!</strong>
                </Text>
              ) : (
                <></>
              )
            ) : (
              <Text c="text2.4" fs="italic">
                Subscription will be canceled at <strong>next renewal</strong>{' '}
                date.
              </Text>
            )}
          </Flex>
          <Flex direction="column" justify="end" mt={{ base: '1rem', md: 0 }}>
            <Flex align="center" justify="start">
              <Text size="2rem" mr="1rem" tt="uppercase" fw="bold">
                ${sub.price}
              </Text>
              <Badge
                color="badge.2"
                classNames={{
                  root: `${classes.subBadge} ${sub.is_canceled ? classes.canceledSubBadge : ''}`,
                  label: `${classes.subBadgeLabel} ${sub.is_canceled ? classes.canceledSubBadgeLabel : ''}`,
                }}
              >
                {sub.subscription_type[0]}
                <span style={{ textTransform: 'lowercase' }}>
                  {sub.subscription_type.substring(1)}
                </span>
              </Badge>
            </Flex>
            {sub.is_canceled ? (
              <Text c="text.4" fw={600}>
                Cancel Date: {prettyDate}
              </Text>
            ) : (
              <Text c="text2.4" fw={600}>
                Renewal Date: {prettyDate}
              </Text>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default CurrentPlan;
