import React, { useMemo } from 'react';
import { Flex, Title, Card, Button, Text, Box } from '@mantine/core';
import confirmBg from './ConfirmBG.svg';
import { ConfirmEnvelope } from 'images';
import { Link, useLoaderData, useNavigation } from 'react-router-dom';
import { DowngradeConfirmationLoaderData } from 'store';
import { getPrettyDate } from 'helpers';
import { useGLGOParams } from 'hooks';

export const DowngradeConfirmationPage = () => {
  const { projectId } = useGLGOParams();
  const { activeSubscription } =
    useLoaderData() as DowngradeConfirmationLoaderData;
  const { state } = useNavigation();
  const loading = state !== 'idle';

  const {
    next_renewal_date,
    next_subscription: {
      price: nextSubPrice,
      sku: nextSubSku,
      sku_name: nextSubSkuName,
      threshold_value: nextSubThresholdValue = '',
    } = {},
  } = activeSubscription;

  const prettyRenewalDate = useMemo(
    () => getPrettyDate(next_renewal_date),
    [next_renewal_date]
  );

  return (
    <Flex
      mih="calc(100vh - 48px)"
      pt="2rem"
      pb="4rem"
      justify="center"
      direction="column"
      style={{
        backgroundImage: `url(${confirmBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Card
        maw={{ base: '95%', sm: '80%', md: '60%' }}
        mx="auto"
        p={{ base: '2rem 1rem', xs: '2rem', sm: '3rem' }}
        radius="md"
        shadow="sm"
        withBorder
      >
        <Flex direction="column" align="center" gap={!nextSubSku ? 10 : 0}>
          {!nextSubSku ? (
            <Title order={1} mb="0.25rem" ta="center">
              No Downgrade Found
            </Title>
          ) : (
            <Box opacity={loading ? 0.2 : 1}>
              <Title order={1} mb="0.25rem" ta="center">
                Thank You For Choosing GlobalLink WEB!
              </Title>
              <Flex justify="center">
                <ConfirmEnvelope name="envelope" />
              </Flex>
              <Text
                ta="center"
                m={{ base: '1rem', sm: '2rem', lg: '3rem' }}
                size="md"
              >
                Your subscription will be changed to {nextSubSkuName} on{' '}
                {prettyRenewalDate}, which entitles you to{' '}
                {nextSubThresholdValue.toLocaleString()} words per month. Your
                current subscription will continue until the end of this billing
                cycle. The payment method you have on file will not be charged
                at this time. At the next billing cycle you will be charged $
                {nextSubPrice} plus any applicable taxes and fees. A
                confirmation email has been sent to the account on file.
              </Text>
              <Text
                ta="center"
                mx={{ base: '1rem', sm: '2rem', lg: '3rem' }}
                mb="2rem"
                size="md"
              >
                If you don't receive a confirmation email after 24 hours,
                contact us for support at: support@globalinkgo.com.
              </Text>
            </Box>
          )}
          <Link to={`/${projectId}/dashboard`}>
            <Button>Back to Dashboard</Button>
          </Link>
        </Flex>
      </Card>
    </Flex>
  );
};

export default DowngradeConfirmationPage;
