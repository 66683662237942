import 'mantine-datatable/styles.css';
import {
  Card,
  Flex,
  Text,
  Tooltip,
  Stack,
  LoadingOverlay,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { isInContextWindow } from 'helpers';
import { InfoIcon } from '../../../../../StaticIcons';
import { NoTxRuleForm } from './NoTxRuleForm';
import { NoTranslateXRule } from './NoTxEditor';
import { DeleteNoTxRule } from './DeleteNoTxRule';
import { previewNoTxRule } from './contextFunctions';
import { useNoTxColors } from './useNoTxColors';
import CustomTable from '../../CustomTable';
import { MRT_ColumnDef, MRT_RowData } from 'mantine-react-table';
import { getBasicColumn, getEditDeleteColumn } from '../../utils';
import React, { Dispatch, SetStateAction } from 'react';

type Props = {
  rules: NoTranslateXRule[];
  yyTarget: TranslationKey | undefined;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  selectRule?: (id: number) => void;
};

const isIncontext = isInContextWindow();

export const NoTxRulesTable = ({
  rules,
  selectRule,
  yyTarget,
  setLoading,
  loading,
}: Props) => {
  const colors = useNoTxColors().table;
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const heightOffset = isMobileSize && !isIncontext ? 380 : 300;

  function handleRowClick(rule: NoTranslateXRule) {
    previewNoTxRule(rule.selector);
    selectRule && selectRule(rule.id);
  }

  const columns: MRT_ColumnDef<NoTranslateXRule>[] = [
    {
      ...getBasicColumn('order', ''),
      size: 65,
      grow: false,
      mantineTableHeadCellProps: { pl: 0 },
    },
    {
      ...getBasicColumn('selector.X', 'selector', true),
      Header: (
        <Flex align="center" tt="uppercase">
          <Text fw={700}>SELECTOR</Text>
          <Tooltip label="This supports any CSS selector.">
            <Flex align={'center'}>
              <InfoIcon fill="#2C49AC" size={15} />
            </Flex>
          </Tooltip>
        </Flex>
      ),
    },
    {
      ...getBasicColumn('selector.comment', 'description', true),
    },

    getEditDeleteColumn(
      ({ data }: { data: MRT_RowData }) => (
        <NoTxRuleForm
          id={data.id}
          rules={rules}
          yyTarget={yyTarget}
          setLoading={setLoading}
        />
      ),
      ({ data }: { data: MRT_RowData }) => (
        <DeleteNoTxRule
          id={data.id}
          yyTarget={yyTarget}
          setLoading={setLoading}
        />
      )
    ),
  ];

  const mobileColumns: MRT_ColumnDef<NoTranslateXRule>[] = [
    {
      ...getBasicColumn('order', ''),
      size: 65,
      grow: false,
      mantineTableHeadCellProps: { pl: 0 },
    },
    {
      ...getBasicColumn('selector.X', 'selector'),
      Header: (
        <Flex align="center" tt="uppercase">
          <Text>SELECTOR</Text>
          <Tooltip label="This supports any CSS selector.">
            <Flex align={'center'}>
              <InfoIcon fill="#2C49AC" size={15} />
            </Flex>
          </Tooltip>
        </Flex>
      ),
      Cell: ({ cell, row }) => (
        <Tooltip
          label={
            <Stack>
              <Text inherit>{cell.getValue() as string}</Text>
              {row.original.selector.comment && (
                <Text style={{ whiteSpace: 'wrap' }} inherit>
                  {row.original.selector.comment as string}
                </Text>
              )}
            </Stack>
          }
        >
          <Text truncate>{cell.getValue() as string}</Text>
        </Tooltip>
      ),
    },
    getEditDeleteColumn(
      ({ data }: { data: MRT_RowData }) => (
        <NoTxRuleForm
          rules={data.rules}
          yyTarget={data.yyTarget}
          setLoading={setLoading}
        />
      ),
      ({ data }: { data: MRT_RowData }) => (
        <DeleteNoTxRule
          id={data.id}
          yyTarget={data.yyTarget}
          setLoading={setLoading}
        />
      )
    ),
  ];

  return (
    <Card p={0}>
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <CustomTable
        data={rules}
        columns={isMobileSize ? mobileColumns : columns}
        mantineTableBodyRowProps={({ row }) =>
          isIncontext
            ? {
                fw: row.original.isOnPage ? 400 : 100,
                fs: row.original.isOnPage ? 'normal' : 'italic',
                bg: row.original.isSelected
                  ? colors.background.selected
                  : colors.background.normal,
                c: row.original.isSelected
                  ? colors.font.selected
                  : colors.font.normal,
                onClick: () => {
                  handleRowClick(row.original);
                },
                style: {
                  cursor: 'pointer',
                  borderColor: '#CBD1F9',
                },
              }
            : { style: { borderColor: '#CBD1F9' } }
        }
        containerProps={{
          style: {
            maxHeight: `calc(100vh - ${heightOffset}px)`,
            minHeight: 300,
          },
        }}
        defaultColumn={{ size: 175 }}
        bottomToolbarString={rules.length === 1 ? 'Rule' : 'Rules'}
      />
    </Card>
  );
};
