const AnnualDiscountAccent = () => (
  <svg
    width="168"
    height="8"
    viewBox="0 0 168 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 7V3.77541C73.1151 0.0402176 109.326 0.10967 167 3.77541C99.8107 2.72675 63.3526 3.30736 1 7Z"
      fill="#0071DB"
      stroke="#0071DB"
      strokeWidth="1.58333"
    />
  </svg>
);

export default AnnualDiscountAccent;
