import { Text, UnstyledButton } from '@mantine/core';
import { getYYTarget } from 'helpers';
import { useGLGOParams } from 'hooks';
import { useSubmit } from 'react-router-dom';
import { useCurrentProjectData } from 'store';
import CustomModal from '../../CustomModal';

type Props = {
  showSpecifyModal: boolean;
  setShowSpecifyModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTranslations: Partial<LanguageCode>[];
  setSelectedTranslations: React.Dispatch<
    React.SetStateAction<Partial<LanguageCode>[]>
  >;
};

export const SpecifyModal = ({
  showSpecifyModal,
  setShowSpecifyModal,
  selectedTranslations,
}: Props) => {
  const submit = useSubmit();
  const { projectId = '', projectKey = '' } = useGLGOParams();
  const { project: { translations = [], deployment_method = '' } = {} } =
    useCurrentProjectData();
  const yyTarget = getYYTarget(translations);

  const addLanguages = (shouldRedirect?: boolean) => {
    setShowSpecifyModal(false);
    submit(
      {
        projectKey,
        yyTarget: JSON.stringify(yyTarget),
        selectedTranslations: JSON.stringify(selectedTranslations),
        deployment_method,
        redirectRoute: shouldRedirect
          ? `/${projectId}/settings/deployment?edit=1`
          : '',
      },
      {
        method: 'post',
        encType: 'application/json',
      }
    );
  };

  return (
    <CustomModal
      size="lg"
      title={`Specify ${deployment_method}s`}
      opened={showSpecifyModal}
      onClose={() => setShowSpecifyModal(false)}
      footerActions={[
        { label: 'Cancel', action: () => setShowSpecifyModal(false) },
        { label: 'Confirm', action: () => addLanguages() },
      ]}
    >
      <Text fz="1.15rem">
        Your project is deployed using{' '}
        <Text fz="1.15rem" span>
          {deployment_method}
        </Text>
        s. You must specify the <Text span>{deployment_method}</Text>
        s for these languages in order for them to be added to your project,
        otherwise the language will not appear in the language selector.
        <br />
        <br />
        <UnstyledButton
          c="text.8"
          fz="1.15rem"
          fw={600}
          onClick={() => addLanguages(true)}
          px={0}
        >
          Go to your deployment configuration
        </UnstyledButton>
      </Text>
    </CustomModal>
  );
};
