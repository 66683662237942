import React from 'react';
import { Button, Divider, Flex, Modal, ScrollArea, Text } from '@mantine/core';
import { LinkWrapper } from './Generic';
import { To } from 'react-router-dom';
import classes from './CustomModal.module.css';

type FooterAction = {
  label: React.ReactNode;
  action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  type?: 'button' | 'submit';
  formId?: string;
  to?: To;
  target?: React.HTMLAttributeAnchorTarget;
  variant?: 'default' | 'light' | 'outline';
};

type CustomModal = {
  opened: boolean;
  onClose: () => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  children?: React.ReactNode;
  title?: React.ReactNode;
  footerActions?: [FooterAction] | [FooterAction, FooterAction];
  keepOpen?: boolean;
  withCloseButton?: boolean;
  size?: 'md' | 'lg' | '100%';
  id?: string;
  flex?: boolean;
  minHeight?: string | number;
};

const buttonVariants = ['outline', 'default'];

export default function CustomModal({
  opened,
  onClose,
  onClick,
  children,
  title,
  footerActions,
  keepOpen = false,
  withCloseButton = true,
  size,
  id,
  flex,
  minHeight,
}: CustomModal) {
  return (
    <Modal
      id={id}
      centered
      opened={opened}
      onClick={onClick}
      onClose={onClose}
      withCloseButton={withCloseButton}
      size={size || 'md'}
      closeOnClickOutside={!keepOpen}
      closeOnEscape={!keepOpen}
      zIndex={1000}
      styles={{
        content: {
          height: minHeight || 'auto',
        },
      }}
    >
      {title && (
        <>
          <Text ta="center" fz="1.5rem" fw={700} tt="capitalize">
            {title}
          </Text>
          {children && <Divider mt="0.5rem" color="divider.1" />}
        </>
      )}
      {children && (
        <ScrollArea
          w="100%"
          classNames={{
            root: classes.scrollAreaRoot,
            viewport: flex ? classes.flexViewport : classes.blockViewport,
          }}
          styles={{
            viewport: {
              display: flex ? 'flex' : 'block',
              paddingTop: '1rem',
              paddingBottom: footerActions ? '2rem' : 0,
            },
          }}
          px="1rem"
          type="auto"
          scrollbarSize={16}
        >
          {children}
        </ScrollArea>
      )}
      {footerActions && (
        <>
          <Divider mb="1rem" mt={children ? 0 : '2rem'} color="divider.1" />
          <Flex direction="row" justify="flex-end" columnGap="0.75rem">
            {footerActions.map(
              (
                { label, action, disabled, type, formId, to, target, variant },
                index
              ) => {
                const defaultVariant =
                  buttonVariants[footerActions.length === 1 ? 1 : index];

                return (
                  <LinkWrapper
                    key={typeof label === 'string' ? label : index.toString()}
                    to={to}
                    target={target}
                  >
                    <Button
                      onClick={action}
                      disabled={disabled}
                      type={type}
                      form={type === 'submit' ? formId : undefined}
                      variant={variant || defaultVariant}
                    >
                      {label}
                    </Button>
                  </LinkWrapper>
                );
              }
            )}
          </Flex>
        </>
      )}
    </Modal>
  );
}
