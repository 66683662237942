import React, { useState } from 'react';
import { Card, Container, Flex, Text } from '@mantine/core';
import { MdCloudDownload } from 'react-icons/md';
import {
  type BillingHistory,
  useCurrentProjectData,
  usePayInvoiceStore,
} from 'store';
import moment from 'moment/moment';
import { Header, NoDataFoundPlaceHolder } from '../../Generic';
import { useMediaQuery } from '@mantine/hooks';
import LoadingSkeleton from '../godashboard/LoadingSkeleton';
import { useNavigation } from 'react-router-dom';
import { MRT_ColumnDef } from 'mantine-react-table';
import CustomTable from '../CustomTable';
import { getBasicColumn } from '../utils';

const InvoiceDownloadIcon = ({
  invoiceFileId = '',
}: {
  invoiceFileId: string;
}) => {
  const { downloadInvoice } = usePayInvoiceStore();
  const { project } = useCurrentProjectData();
  const project_key = project?.project_key || '';

  const hasGeneratedInvoiceFile = invoiceFileId.length > 0;

  return hasGeneratedInvoiceFile ? (
    <Flex
      style={{ cursor: 'pointer' }}
      onClick={() => downloadInvoice(invoiceFileId, project_key)}
    >
      <MdCloudDownload fontSize={22} color="var(--mantine-color-font1-1)" />
    </Flex>
  ) : (
    <Flex justify="center">Pending...</Flex>
  );
};

type Props = {
  billingHistory: BillingHistory[];
};

const BillingHistory = ({ billingHistory }: Props) => {
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const { state } = useNavigation();
  const loading = state !== 'idle';

  const [isMobileTableExpanded, setIsMobileTableExpanded] = useState(false);

  const columns: MRT_ColumnDef<BillingHistory>[] = [
    {
      ...getBasicColumn('updatedTime', 'date issued'),
      Cell: ({ cell }) => {
        const dateIssued =
          moment(cell.getValue() as string).format('MM/DD/YYYY') || '';
        return (
          <Text fw={400} fz="1rem">
            {dateIssued}
          </Text>
        );
      },
    },
    {
      ...getBasicColumn('item.productDetails.name', 'sku name'),
    },
    {
      ...getBasicColumn('id', 'invoice id'),
      Cell: ({ cell }) => {
        const invoiceId = cell.getValue() as string;
        const hiddenId = invoiceId.substring(0, 5) + 'XXXXX' || invoiceId || '';
        return (
          <Text fw={400} fz="1rem">
            #{hiddenId}
          </Text>
        );
      },
    },
    {
      ...getBasicColumn('capturedAmount', 'amount (USD)'),
      Cell: ({ cell }) => {
        return `$${(cell.getValue() as number).toFixed(2)}` || '';
      },
    },
    {
      ...getBasicColumn('invoiceFiles.0.id', 'invoice'),
      Cell: ({ cell }) => (
        <InvoiceDownloadIcon invoiceFileId={cell.getValue() as string} />
      ),
      enableSorting: false,
      size: 115,
      grow: false,
    },
  ];

  const mobileColumns: MRT_ColumnDef<BillingHistory>[] = [
    {
      ...getBasicColumn('updatedTime', 'date issued'),
      Cell: ({ cell }) => {
        const dateIssued =
          moment(cell.getValue() as string).format('MM/DD/YYYY') || '';
        return (
          <Text fw={400} fz="1rem">
            {dateIssued}
          </Text>
        );
      },
    },
    {
      ...getBasicColumn('id', 'invoice id'),
      Cell: ({ cell }) => {
        const invoiceId = cell.getValue() as string;
        const hiddenId = invoiceId.substring(0, 5) + 'XXXXX' || invoiceId || '';
        return (
          <Text fw={400} fz="1rem">
            #{hiddenId}
          </Text>
        );
      },
    },
    {
      ...getBasicColumn('invoiceFiles.0.id', 'invoice'),
      Cell: ({ cell }) => (
        <InvoiceDownloadIcon invoiceFileId={cell.getValue() as string} />
      ),
      Header: () => (
        <Flex
          w="100%"
          justify="center"
          style={{ cursor: 'pointer' }}
          onClick={() => setIsMobileTableExpanded(true)}
        >
          <Text fw={700} fz="1.5rem">
            ...
          </Text>
        </Flex>
      ),
      enableSorting: false,
      size: 95,
      grow: false,
    },
  ];

  return (
    <Container m={0} my={20} p={0} maw="100%">
      <Header title="Billing History" />
      <Card p={loading ? 23 : 0} radius={0}>
        {loading ? (
          <LoadingSkeleton height={630} />
        ) : billingHistory.length === 0 ? (
          <NoDataFoundPlaceHolder height={676} />
        ) : (
          <CustomTable
            data={billingHistory}
            columns={
              isMobileSize && !isMobileTableExpanded ? mobileColumns : columns
            }
            bottomToolbarString={
              billingHistory.length === 1 ? 'Invoice' : 'Invoices'
            }
          />
        )}
      </Card>
    </Container>
  );
};

export default BillingHistory;
