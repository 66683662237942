import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { TextInput, Button, Flex } from '@mantine/core';
import {
  fetchXapisUser,
  generateFakeAuthUser,
  shouldSkipSSO,
  useAuth,
  getAuthManager,
} from 'auth';
import { useUserProvider } from 'store';
import { dashboardRC } from 'helpers';

export const DevLogin = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { setXapisUser } = useUserProvider();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isAuthenticated, isLoading } = useAuth();

  const redirectUrl = state?.path || dashboardRC.PAGE_ROOT;

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate(redirectUrl, { replace: true });
    }
  }, [isAuthenticated, isLoading]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setEmail(e.target.value);
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return;
    const { xapisUser } = await fetchXapisUser(email);
    if (!xapisUser) {
      setError('Please check your email and try again');
      return;
    } else {
      const authManager = getAuthManager();
      setXapisUser(xapisUser);
      // Generate fake auth user
      const authUser = await generateFakeAuthUser(email);
      // Load user (this will trigger auth provider's state update)
      authManager.events.load(authUser);
    }
  };

  if (!shouldSkipSSO()) {
    return <Navigate to={dashboardRC.PAGE_ROOT} />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'white',
      }}
    >
      <h2>Howdy Localhosters! 👋</h2>
      <form onSubmit={handleSubmit}>
        <Flex gap={20} direction={'column'}>
          <TextInput
            type="email"
            id="email"
            label="Your Email"
            placeholder="email"
            styles={{
              label: { fontSize: '1rem' },
              input: { fontSize: '1rem', width: '320px' },
            }}
            value={email}
            onChange={handleChange}
            error={error}
            required
          />
          <Button type="submit">Login</Button>
        </Flex>
      </form>
    </div>
  );
};
