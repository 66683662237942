import React from 'react';
import { Box, Flex, Grid, Menu, Text, Tooltip } from '@mantine/core';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import { BasicHorizontalDivider } from '../../../Generic';
import classes from './InactiveTranslationItem.module.css';

type Props = {
  translation: Partial<TranslationKey>;
  sourceLangName: string;
  addInactiveTranslation?: (targetLangCode: string) => void;
  isLastItem: boolean;
};

export const InactiveTranslationItem = ({
  translation,
  sourceLangName,
  addInactiveTranslation,
  isLastItem,
}: Props) => {
  const { target_lang_name = '', translation_key = '' } = translation || {};

  const isValidTarget = target_lang_name !== sourceLangName;
  const sourceLanguage =
    sourceLangName?.substring(0, sourceLangName.indexOf('(')).slice(0, -1) ||
    'English';

  return target_lang_name && isValidTarget ? (
    <>
      <Grid align="center" my="auto" p={20} grow>
        <Grid.Col span={10}>
          <Flex direction="row" align="center" columnGap={20}>
            <Text fs="italic" c="text2.8" fw={500}>
              {sourceLanguage}
            </Text>
            <MdOutlineArrowForwardIos size={20} color="#707070" />
            <Text fs="italic" c="text2.8" fw={500}>
              {target_lang_name}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={2}>
          {addInactiveTranslation && (
            <Flex justify="flex-end">
              <Menu
                width={110}
                shadow="sm"
                position="bottom-end"
                zIndex={20}
                classNames={{
                  dropdown: classes.dropdown,
                  item: classes.item,
                }}
              >
                <Menu.Target>
                  <Box h={20} style={{ cursor: 'pointer' }}>
                    <Tooltip
                      position="top"
                      events={{ hover: false, touch: true, focus: false }}
                      label="Reactivate"
                    >
                      <Box>
                        <IoMdAdd color="#0071DB" size={24} />
                      </Box>
                    </Tooltip>
                  </Box>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => {
                      addInactiveTranslation(translation_key);
                    }}
                  >
                    Reactivate
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Flex>
          )}
        </Grid.Col>
      </Grid>
      {!isLastItem && (
        <BasicHorizontalDivider borderWidth={2} mobileMargin="0" />
      )}
    </>
  ) : (
    <></>
  );
};

export default InactiveTranslationItem;
