import { Text } from '@mantine/core';
import { UpgradeParams, upgrade } from '../planActions';
import { ActionModalType } from '../../select-plans/SelectPlan';
import CustomModal from '../../../../CustomModal';

type Props = {
  modalType: 'downgrade' | 'upgrade' | null;
  setActionModalType: React.Dispatch<React.SetStateAction<ActionModalType>>;
  params: UpgradeParams;
};

const UpgradeConfirmModal = ({
  modalType,
  setActionModalType,
  params,
}: Props) => (
  <CustomModal
    size="lg"
    title="Enjoy your new plan right away!"
    opened={modalType === 'upgrade'}
    onClose={() => setActionModalType(null)}
    footerActions={[
      {
        label: 'Upgrade',
        action: async () => {
          setActionModalType(null);
          await upgrade({ ...params });
        },
      },
    ]}
  >
    <Text fz="1.15rem" ta="center">
      Your upgrade is effective immediately. When you checkout, you will only be
      charged for the difference between your current plan and the new plan.
      Your renewal date stays the same and your saved payment method will be
      charged at the new rate at your next renewal.
    </Text>
  </CustomModal>
);

export default UpgradeConfirmModal;
