import React from 'react';
import { Divider, Flex, Text } from '@mantine/core';
import { StatusCircleIcon } from '../../../Generic';

type Props = {
  statusTitle: string;
  status: 'live' | 'active' | 'inactive' | 'private';
  showStatusIcon?: boolean;
};

export const TranslationStatusHeader = ({
  statusTitle = 'Active',
  status = 'active',
  showStatusIcon = false,
}: Props) => {
  return (
    <Flex
      mt="1rem"
      direction="row"
      justify="space-between"
      columnGap={25}
      align="center"
    >
      {showStatusIcon && (
        <StatusCircleIcon status={status} size={20} borderSize={4} />
      )}
      <Divider
        label={
          <Text
            fz="1rem"
            fw={700}
            data-testid="pw-project-settings-manage-languages-languages-count"
          >
            {statusTitle}
          </Text>
        }
        labelPosition="left"
        w="100%"
        color="border.1"
        size="sm"
      />
    </Flex>
  );
};

export default TranslationStatusHeader;
