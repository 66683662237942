import React, { useCallback, useEffect } from 'react';
import { Box, Flex, Grid, Image } from '@mantine/core';
import {
  CircleAvatar,
  StepsSwitch,
  StepsTimeline,
  useSetupStepsContext,
} from 'ui';
import { ProjectStore, useProjectsStore } from 'store';
import { useMediaQuery } from '@mantine/hooks';
import GLWEBLogoColor from 'images/logos/glweb-single-level-logo.svg';

const ProjectSetupSteps = () => {
  const isDesktopSize = useMediaQuery('(min-width: 1200px)');

  const { activeProject } = useProjectsStore((state: ProjectStore) => state);
  const { activeStep, setActiveStep, projectDomain } = useSetupStepsContext();
  const isInformativeStep =
    activeStep === 0 || activeStep === 2 || activeStep === 5;

  const isActiveProjectVerified = useCallback(
    (projectStatus: string) => projectStatus !== 'UNVERIFIED',
    []
  );

  useEffect(() => {
    const { projectStatus = '', projectKey = '' } = activeProject;
    if (projectKey && !isActiveProjectVerified(projectStatus)) {
      setActiveStep(2);
    }
  }, [activeProject, isActiveProjectVerified, setActiveStep]);

  return (
    <Box w="100%" h="max-content" bg="background.1">
      <Grid m={0} gutter={0}>
        <Grid.Col p={0} m={0} span={{ base: 12, lg: 5 }}>
          <Flex
            h="100vh"
            mih={{ base: '100%', lg: activeStep === 0 ? 500 : 900 }}
            direction="column"
            justify={{ base: 'flex-start', lg: 'center' }}
            mr={{ base: 0, md: '-0.5rem' }}
            bg="background.1"
            pos="relative"
            style={{
              borderTopRightRadius: isDesktopSize ? '20px' : 0,
              borderBottomRightRadius: isDesktopSize ? '20px' : 0,
              boxShadow: isDesktopSize
                ? '-7px 4px 17px 0px rgba(7, 29, 73, 1)'
                : 'none',
              zIndex: 20,
            }}
          >
            <Flex
              w="100%"
              h={{ base: 70, lg: 'auto' }}
              px={{ base: '1rem', lg: 0 }}
              bg={{ base: 'background.0', lg: 'transparent' }}
              pos={{ base: 'fixed', lg: 'absolute' }}
              top={{ base: 0, lg: '2rem' }}
              left={{ base: 0, lg: '2rem' }}
              justify="space-between"
              align="center"
              style={{
                boxShadow: !isDesktopSize
                  ? '-7px 4px 5px 0px rgba(7, 29, 73, 0.1)'
                  : 'none',
                zIndex: 999,
              }}
            >
              <Image
                src={GLWEBLogoColor}
                width={isDesktopSize ? 220 : 170}
                style={{
                  width: isDesktopSize ? '220px' : '170px',
                  height: 'auto',
                }}
                alt="GlobalLink WEB logo"
              />
              {!isDesktopSize && activeStep >= 1 ? (
                <CircleAvatar
                  name={projectDomain}
                  circleSize="2.5rem"
                  fontSize="1rem"
                />
              ) : (
                <></>
              )}
            </Flex>
            <Flex
              w="100%"
              px={{ base: 0, md: '4rem' }}
              pt={{ base: '6rem', lg: 0 }}
              pb={{ base: '2rem', lg: 0 }}
              direction="column"
              justify={{ base: 'flex-start', lg: 'center' }}
              align="center"
              rowGap={{ base: '3rem', xs: '4rem', lg: 0 }}
            >
              {!isDesktopSize ? <StepsTimeline /> : <></>}
              <StepsSwitch contentType="side-menu" />
            </Flex>
          </Flex>
        </Grid.Col>
        <Grid.Col
          display={isDesktopSize ? 'block' : 'none'}
          bg="background.0"
          p={0}
          m={0}
          span={{ base: 12, lg: 7 }}
        >
          <Flex
            h="100vh"
            mih={activeStep === 0 ? 500 : 900}
            direction="column"
            justify="center"
            pl="0.5rem"
            pos="relative"
            style={{ zIndex: 10 }}
          >
            {!isInformativeStep && <StepsTimeline />}
            <StepsSwitch contentType="body" />
          </Flex>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default ProjectSetupSteps;
