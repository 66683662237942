import React, { useCallback, useMemo } from 'react';
import { Card, Flex, Progress, Text, Tooltip } from '@mantine/core';
import LoadingSkeleton from '../godashboard/LoadingSkeleton';
import { MRT_ColumnDef } from 'mantine-react-table';
import CustomTable from '../CustomTable';
import { getBasicColumn } from '../utils';

type Props = {
  worldTraffic: Array<{
    count: number;
    country_name: string;
    label: string;
  }>;
  loading: boolean;
  timeSlice: string;
};

export const CountryTable = ({ worldTraffic, loading, timeSlice }: Props) => {
  const totalTrafficCount = worldTraffic.reduce(
    (accum, { count = 0 }) => accum + count,
    0
  );

  const calculateTrafficPercentage = useCallback(
    (count: number) => {
      if (totalTrafficCount > 0) {
        return Math.round((count / totalTrafficCount) * 100);
      }
      return 0;
    },
    [totalTrafficCount]
  );

  const calculateTimeString = () => {
    switch (timeSlice) {
      case '1 day': {
        return 'day';
      }
      case '1 month': {
        return 'month';
      }
      case '1 year': {
        return 'year';
      }
      default: {
        return timeSlice + 's';
      }
    }
  };

  const tableData = useMemo(() => {
    return worldTraffic
      .filter(({ country_name }) => country_name)
      .map(({ country_name, count }) => ({
        country: country_name,
        visits: count,
        percent: calculateTrafficPercentage(count),
      }))
      .sort((a, b) => b.visits - a.visits);
  }, [calculateTrafficPercentage, worldTraffic]);

  const columns: MRT_ColumnDef<(typeof tableData)[0]>[] = [
    {
      ...getBasicColumn('country', 'country', true),
    },
    {
      ...getBasicColumn('visits', 'visits'),
      Cell: ({ cell }) => {
        const visits = cell.getValue() as number;
        return (
          <Tooltip label={visits.toLocaleString()}>
            <Text fw={700} truncate>
              {visits.toLocaleString()}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'percent',
      header: '',
      Cell: ({ cell, row }) => {
        const percentage = cell.getValue() as number;
        return (
          <Tooltip
            label={`This is the percentage of total traffic to your site from ${row.original.country} over the past ${calculateTimeString()}.`}
          >
            <Flex w="100%" align="center" gap={10}>
              <Progress value={percentage} w="100%" />
              <Text fw={700} fz="1rem">
                {percentage}%
              </Text>
            </Flex>
          </Tooltip>
        );
      },
    },
  ];

  if (loading) {
    return (
      <Card h={452} p={20}>
        <LoadingSkeleton />
      </Card>
    );
  }

  return (
    <Card p={0}>
      <CustomTable
        data={tableData}
        columns={columns}
        enableBottomToolbar={false}
        paperProps={{ style: { boxShadow: 'none' } }}
        tableProps={{ mah: 450 }}
        enableSorting={false}
        enableRowVirtualization={false}
        containerProps={{ mih: 450, mah: 450 }}
      />
    </Card>
  );
};

export default CountryTable;
