import { useEffect } from 'react';
import {
  LIVE_GLGO_DASHBOARD_URL,
  REMARKET_STATS_BASEURL,
  dashboardRC,
} from 'helpers';
import { useLocation } from 'react-router-dom';

const getSegNameFromPath = (pathname: string) => {
  const path = pathname.substring(1);
  const pageMappings = {
    [dashboardRC.PAGE_CREATE_ACCOUNT]: 'GLWeb:CREATE_ACCOUNT',
    [dashboardRC.PAGE_CREATE_PROJECT]: 'GLWeb:ACCOUNT_COMPLETION',
  } as const;

  if (path in pageMappings) {
    return pageMappings[path as keyof typeof pageMappings];
  }

  return null;
};

const RemarketStatsScript = () => {
  const { pathname = '' } = useLocation();
  const segName = getSegNameFromPath(pathname);

  useEffect(() => {
    if (window.location.origin !== LIVE_GLGO_DASHBOARD_URL) return;

    if (!segName) {
      console.error('remarketstatsscript: segname is not defined');
      return;
    }

    const script = document.createElement('script');
    script.src = REMARKET_STATS_BASEURL + segName;
    script.defer = true;
    script.id = 'remarket-' + segName;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);

      // remarket script injects iframes, need to wipe when navigating
      const correspondingIframes = document.querySelectorAll(
        '[id^="_ccpx_cont_"]'
      );
      if (correspondingIframes.length) {
        correspondingIframes.forEach((iframe) => {
          document.body.removeChild(iframe);
        });
      }
    };
  }, [pathname, segName]);

  return <></>;
};

export default RemarketStatsScript;
