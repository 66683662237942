/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AppShell,
  MantineComponent,
  createTheme,
  Text,
  ActionIcon,
  Button,
  UnstyledButton,
  Card,
  Tooltip,
  Modal,
  Progress,
  Checkbox,
  Select,
  ScrollArea,
  MantineThemeOverride,
  Anchor,
  Accordion,
  Switch,
  Title,
  Slider,
  Menu,
  TextInput,
} from '@mantine/core';
import cardClasses from './modules/Card.module.css';
import tooltipClasses from './modules/Tooltip.module.css';
import modalClasses from './modules/Modal.module.css';
import progressClasses from './modules/Progress.module.css';
import textClasses from './modules/Text.module.css';
import appShellClasses from './modules/AppShell.module.css';
import unstyledButtonClasses from './modules/UnstyledButton.module.css';
import actionIconClasses from './modules/ActionIcon.module.css';
import checkboxClasses from './modules/Checkbox.module.css';
import selectClasses from './modules/Select.module.css';
import scrollAreaClasses from './modules/ScrollArea.module.css';
import anchorClasses from './modules/Anchor.module.css';
import accordionClasses from './modules/Accordion.module.css';
import switchClasses from './modules/Switch.module.css';
import titleClasses from './modules/Title.module.css';
import sliderClasses from './modules/Slider.module.css';
import menuClasses from './modules/Menu.module.css';
import textInputClasses from './modules/TextInput.module.css';

import buttonClasses from '../shared/modules/Button.module.css';
import { ctaColors } from '../shared/colors';

export const dashboardTheme: MantineThemeOverride = createTheme({
  fontFamily: 'OpenSans, sans-serif',
  colors: {
    cta1: ctaColors as any,
    secondary1: ['#6E6ADD'] as any,
    font1: ['#003B71', '#334552'] as any,
    background1: ['#003B71'] as any,
    border1: ['#DBE1E5'] as any,
    icons1: ['#0071DB', '#00328D', '#757575'] as any,
    divider1: ['#CFD2D6'] as any,
    // TODO: Remove old colors once fully migrated to new style guide
    text: [
      '#FFFFFF',
      '#00328D',
      '#071D49',
      '#757575',
      '#D93025',
      '#188038',
      '#14CD5E',
      '#D93025',
      '#0071DB',
      '#868E96',
    ],
    text2: [
      '#2D3748',
      '#212529',
      '#000000',
      '#1A0DAB',
      '#687897',
      '#61759E',
      '#2C49AC',
      '#637392',
      '#707070',
      '#AB1A1F',
    ],
    text3: [
      '#E8F2EA',
      '#EAF0FF',
      '#0071DB',
      '#677689',
      '#003B71',
      '#139DD8',
    ] as any,
    background: [
      '#FFFFFF',
      '#FAFBFF',
      '#E8EBFC',
      '#8F9DBA',
      '#14CD5E',
      '#071D49',
      '#212529',
      '#F8F9FA',
      '#F5F5F5',
    ] as any,
    background2: [
      '#EFF4FB',
      '#188038',
      '#0071DB',
      '#707070',
      '#637392',
      '#EAF0FF',
      '#2C49AC',
      '#E4E7FA',
      '#E9EBFB',
      '#6E6ADD',
    ] as any,
    border: [
      '#E7F3FF',
      '#CBD1F9',
      '#637392',
      '#188038',
      '#D0DBF4',
      '#071D49',
      '#CED4DA',
      '#D9D9D9',
      '#0071DB',
      '#E9ECEF',
    ] as any,
    border2: [
      '#BAD9C3',
      '#C8DFF8',
      '#D1D1D4',
      '#D5DAE3',
      '#E8EBFC',
      '#D1D7FA',
      '#EAF0FF',
      '#2C49AC',
      '#757575',
    ] as any,
    border3: ['#EEEEEE'] as any,
    divider: ['#D1D7FA', '#CBD1F9', '#EAF0FF'] as any,
    badge: [
      '#EEEEEE',
      '#757575',
      '#E8F2EB',
      '#188038',
      '#656565',
      '#E2F0FF',
      '#E6F1FB',
      '#E9EBF1',
      '#EAF0FF',
      '#E7F3FF',
    ],
    icon: [
      '#0071DB',
      '#CED4DA',
      '#071D49',
      '#5C73BC',
      '#1976D2',
      '#2C49AC',
      '#FBC30C',
    ] as any,
    progressBar: ['#0071DB'] as any,
    checkBox: ['#00328D'] as any,
    scrollBar: ['#0071DB'] as any,
    blue: [
      '#F5FAFF', // 0
      '#E9F1FD', // 1
      '#D0DBF4', // 2
      '#CBDDF9', // 3
      '#A6D4FF', // 4
      '#90A0D3', // 5
      '#5672BC', // 6
      '#0071DB', // 7
      '#2C49AC', // 8
      '#071D49', // 9
    ],
    gray: [
      '#FAFBFF',
      '#EFF4FB',
      '#E4E7FA',
      '#E6E6E8',
      '#D6D9EC',
      '#B9C8E3',
      '#A0A7D6',
      '#8F9DBA',
      '#637392',
      '#252540',
    ],
    tableHeader: ['#DBE1E5'] as any,
  },
  components: {
    AppShell: AppShell.extend({
      classNames: appShellClasses,
    }),
    Text: (Text as MantineComponent<any>).extend({
      classNames: textClasses,
    }),
    Title: Title.extend({
      classNames: titleClasses,
    }),
    ActionIcon: ActionIcon.extend({
      classNames: actionIconClasses,
    }),
    Button: Button.extend({
      classNames: buttonClasses,
      defaultProps: {
        variant: 'default',
      },
    }),
    UnstyledButton: UnstyledButton.extend({
      classNames: unstyledButtonClasses,
    }),
    Card: Card.extend({
      classNames: cardClasses,
    }),
    Tooltip: Tooltip.extend({
      classNames: tooltipClasses,
      defaultProps: {
        multiline: true,
        withArrow: true,
        withinPortal: true,
        maw: 250,
        arrowSize: 10,
        position: 'bottom',
        p: 10,
        zIndex: 999,
        events: { hover: true, focus: false, touch: true },
      },
    }),
    Modal: Modal.extend({
      classNames: modalClasses,
    }),
    Progress: Progress.extend({
      classNames: progressClasses,
      defaultProps: {
        color: 'secondary1.0',
        bg: 'border1.0',
      },
    }),
    Checkbox: Checkbox.extend({
      classNames: checkboxClasses,
    }),
    Select: Select.extend({
      classNames: selectClasses,
    }),
    ScrollArea: ScrollArea.extend({
      classNames: scrollAreaClasses,
    }),
    Switch: Switch.extend({
      classNames: switchClasses,
    }),
    Anchor: Anchor.extend({
      classNames: anchorClasses,
    }),
    Accordion: Accordion.extend({
      classNames: accordionClasses,
    }),
    Slider: Slider.extend({
      classNames: sliderClasses,
    }),
    Menu: Menu.extend({
      classNames: menuClasses,
      defaultProps: {
        arrowSize: 10,
      },
    }),
    TextInput: TextInput.extend({
      classNames: textInputClasses,
    }),
  },
});

export default dashboardTheme;
