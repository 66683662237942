import {
  Box,
  Button,
  Flex,
  Loader,
  ScrollArea,
  Text,
  TextInput,
} from '@mantine/core';
import { useViewSize } from 'hooks';
import React, { useMemo, useState } from 'react';
import { useCurrentProjectData, Xapis } from 'store';
import { asciiToHex, hexToAscii, isSuccessStatus } from 'helpers';
import { notifications } from '@mantine/notifications';
import { BasicHorizontalDivider } from '../../../Generic';

const SelectorLabelEditor = ({
  labels,
}: {
  labels: Record<string, string>[];
}) => {
  const {
    project: { translations, yy_translation_key, project_key },
  } = useCurrentProjectData();
  const yyTranslation = translations?.find(
    (t) => t.translation_key === yy_translation_key
  );

  const { isMobileSize } = useViewSize();
  const [hasEdits, setHasEdits] = useState(false);
  const [newLabels, setNewLabels] = useState({} as Record<string, string>);

  const labelObject = useMemo(() => {
    const refreshedLabels = labels.reduce(
      (acc, label) => {
        const [deploymentValue, displayLabel] = Object.entries(label)[0];
        return { ...acc, [deploymentValue]: displayLabel };
      },
      {} as Record<string, string>
    );
    setNewLabels(refreshedLabels);
    return refreshedLabels;
  }, [labels]);

  const [loading, setLoading] = useState(false);

  const updateSelector = (updatedLabels: Record<string, string>) => {
    setLoading(true);

    const languageSelectorLabels = Object.entries(updatedLabels).map(
      ([langCode, label]) => ({ [langCode]: label })
    );

    const translationConfigString = hexToAscii(
      yyTranslation?.translation_config ?? ''
    );

    const translationConfig = (() => {
      try {
        return JSON.parse(translationConfigString);
      } catch {
        return {};
      }
    })();
    const newTranslationConfig = {
      ...translationConfig,
      translation_rules: {
        ...translationConfig.translation_rules,
        language_selector_labels: languageSelectorLabels,
      },
    };

    Xapis.ProjectTranslation.put(project_key, yy_translation_key, {
      translation_config: asciiToHex(JSON.stringify(newTranslationConfig)),
      translation_config_hash: yyTranslation!.translation_config_hash,
    })
      .then(({ status }) => {
        if (isSuccessStatus(status)) {
          setHasEdits(false);
          notifications.show({
            message: 'Successfully updated your selector widget!',
          });
        } else {
          notifications.show({
            message: 'Oops, there was a problem with saving your edits.',
          });
        }
      })
      .catch(() => {
        notifications.show({
          message: 'Unable to update selector widget at this time.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkForEdits = (updatedLabels: Record<string, string>) => {
    return Object.entries(updatedLabels).some(
      ([key, value]) => labelObject[key] !== value
    );
  };

  const LabelEditor = (label: [string, string], isLast = false) => {
    const [deploymentValue, displayLabel] = label;
    const language = translations?.find(
      (t) => t.deployment_value === deploymentValue
    )?.target_lang_name;
    return (
      <Flex direction="column">
        <Flex justify={'space-between'} align="center" m={10}>
          <Text w="auto">{language}</Text>
          <TextInput
            value={displayLabel}
            w={isMobileSize ? 200 : 300}
            onChange={(e) => {
              const updatedLabels = {
                ...newLabels,
                [deploymentValue]: e.target.value,
              };
              setHasEdits(checkForEdits(updatedLabels));
              setNewLabels(updatedLabels);
            }}
          />
        </Flex>
        {!isLast && <BasicHorizontalDivider borderWidth={2} mobileMargin="0" />}
      </Flex>
    );
  };

  return (
    <>
      <ScrollArea type="always" offsetScrollbars>
        <Flex mah={550} py={5} px={15} direction="column">
          {Object.entries(newLabels).map((label, index, arr) => (
            <Box pb={index === arr.length - 1 ? 10 : 0} key={index}>
              {LabelEditor(label, index === arr.length - 1)}
            </Box>
          ))}
        </Flex>
      </ScrollArea>
      <Flex mt={20} justify="flex-end" gap={20}>
        <Flex columnGap={20} rowGap={10} wrap="wrap" justify="center">
          <Button
            variant="outline"
            disabled={!hasEdits || loading}
            onClick={() => {
              setHasEdits(false);
              setNewLabels(labelObject);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!hasEdits || loading}
            onClick={() => updateSelector(newLabels)}
          >
            {loading ? <Loader color="cta1.6" /> : 'Save'}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
export default SelectorLabelEditor;
