import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import ConfirmEditDomainModal from './ConfirmEditDomainModal';
import {
  FormValues,
  getDefaultStagingDomain,
  onSave,
  removeStagingDomain,
} from './utils';
import { useCurrentProjectData } from 'store';

type Props = {
  setOriginName: React.Dispatch<React.SetStateAction<string>>;
};

const EditDomainForm = ({ setOriginName }: Props) => {
  const { colors } = useMantineTheme();

  const {
    project: {
      project_key = '',
      project_type = '',
      origin_name = '',
      additional_origins = [],
    } = {},
  } = useCurrentProjectData();

  const [stagingDomains, setStagingDomains] = useState(additional_origins);
  const [currentDomain, setCurrentDomain] = useState(origin_name);
  const [currentStagingDomain, setCurrentStagingDomain] = useState(
    additional_origins[0] || ''
  );
  const [editDomain, setEditDomain] = useState(false);
  const [editStagingDomain, setEditStagingDomain] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { register, reset, getFieldState, handleSubmit, watch } =
    useForm<FormValues>({
      defaultValues: {
        domain: currentDomain,
        stagingDomain:
          stagingDomains[0] || getDefaultStagingDomain(currentDomain),
      },
    });

  const resetForm = (data?: Record<string, string>) => {
    reset({
      domain: currentDomain,
      stagingDomain:
        stagingDomains.length > 0
          ? currentStagingDomain
          : getDefaultStagingDomain(data?.domain || currentDomain),
      ...(data || {}),
    });
  };

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSave(
          data,
          getFieldState,
          resetForm,
          setShowConfirmModal,
          editDomain,
          setEditDomain,
          setEditStagingDomain,
          setCurrentStagingDomain,
          stagingDomains,
          setStagingDomains,
          currentDomain,
          project_key,
          project_type
        )
      )}
    >
      <ConfirmEditDomainModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        currentDomain={currentDomain}
        setCurrentDomain={setCurrentDomain}
        newDomain={watch('domain')}
        setEditDomain={setEditDomain}
        resetForm={resetForm}
        setOriginName={setOriginName}
      />
      <Flex w="100%" direction="column">
        <Flex
          w="100%"
          mih="3rem"
          px={{ base: '1rem', sm: '2rem' }}
          align="center"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <Flex
            w={{ base: '100%', sm: '65%' }}
            pr={{ base: 0, sm: '0.5rem' }}
            columnGap="2rem"
            align="center"
          >
            <Box w="4rem">
              <Text fz="1rem" fw={700}>
                Domain
              </Text>
            </Box>
            {editDomain ? (
              <TextInput
                w="100%"
                maw={{ base: '100%', sm: '15rem' }}
                size="md"
                {...register('domain')}
                name="domain"
              />
            ) : (
              <Flex
                w="100%"
                maw={{ base: '100%', sm: '15rem' }}
                mih="3rem"
                columnGap="1rem"
                align="center"
                style={{ overflowX: 'hidden' }}
              >
                <Text truncate>{currentDomain}</Text>
                {!editStagingDomain && (
                  <Box
                    onClick={() => setEditDomain(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    <MdEdit size={18} color={colors.text[8]} />
                  </Box>
                )}
              </Flex>
            )}
          </Flex>
          <Flex
            w={{ base: '100%', sm: '35%' }}
            columnGap="0.5rem"
            justify="flex-end"
          >
            {editDomain ? (
              <>
                <Button
                  variant="outline"
                  onClick={() => {
                    setEditDomain(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            ) : (
              stagingDomains.length === 0 && (
                <Button
                  pr={0}
                  variant="transparent"
                  display={editStagingDomain ? 'none' : 'block'}
                  rightSection={<IoMdAdd size={20} />}
                  onClick={() => {
                    setEditStagingDomain(true);
                  }}
                  style={{
                    alignSelf: 'flex-end',
                  }}
                >
                  Add Staging Site
                </Button>
              )
            )}
          </Flex>
        </Flex>
        {(editStagingDomain || stagingDomains.length > 0) && (
          <>
            <Flex px={{ base: '1rem', sm: '2rem' }} justify="flex-end">
              <Divider
                w="calc(100% - 6rem)"
                color={colors.divider[1]}
                my="0.5rem"
                size="xs"
              />
            </Flex>
            <Flex
              w="100%"
              mih="3rem"
              px={{ base: '1rem', sm: '2rem' }}
              align="center"
              wrap="wrap"
              rowGap="0.5rem"
            >
              <Flex
                w={{ base: '100%', sm: '65%' }}
                pr={{ base: 0, sm: '0.5rem' }}
                columnGap="2rem"
                align="center"
              >
                <Box miw="4rem" />
                {editStagingDomain ? (
                  <TextInput
                    w="100%"
                    maw={{ base: '100%', sm: '15rem' }}
                    size="md"
                    {...register('stagingDomain')}
                    name="stagingDomain"
                  />
                ) : (
                  <Flex
                    w="100%"
                    maw={{ base: '100%', sm: '15rem' }}
                    mih="3rem"
                    columnGap="1rem"
                    align="center"
                    style={{ overflowX: 'hidden' }}
                  >
                    <Text truncate>{currentStagingDomain}</Text>
                    {!editDomain && (
                      <Box
                        onClick={() => setEditStagingDomain(true)}
                        style={{ cursor: 'pointer' }}
                      >
                        <MdEdit size={18} color={colors.text[8]} />
                      </Box>
                    )}
                  </Flex>
                )}
              </Flex>
              <Flex
                w={{ base: '100%', sm: '35%' }}
                columnGap="0.5rem"
                justify="flex-end"
              >
                {editStagingDomain ? (
                  <>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setEditStagingDomain(false);
                        resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                  </>
                ) : (
                  !editDomain && (
                    <Button
                      pr={0}
                      variant="transparent"
                      rightSection={<IoMdRemoveCircleOutline size={24} />}
                      onClick={() => {
                        removeStagingDomain(
                          stagingDomains,
                          setStagingDomains,
                          setCurrentStagingDomain,
                          resetForm,
                          project_key
                        );
                      }}
                    >
                      Remove Staging Site
                    </Button>
                  )
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </form>
  );
};

export default EditDomainForm;
