import { useCurrentProjectData, Xapis } from 'store';
import { asciiToHex, hexToAscii, isSuccessStatus } from 'helpers';
import { Button, Flex, Switch, Text } from '@mantine/core';
import React, { useState } from 'react';
import { flags } from 'helpers';
import { notifications } from '@mantine/notifications';
import classes from '../../gotranslations/glossary/GlossaryRuleForm.module.css';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import SelectorLabelEditor from './SelectorLabelEditor';

const generateLabels = (
  translations: TranslationKey[],
  includeFlags: boolean,
  includeRegion: boolean,
  isStaging: boolean
) => {
  const labels = translations
    .filter(
      (t) =>
        t.target_lang_code !== 'YY' && (t.is_live || isStaging) && t.is_active
    )
    .map((t) => ({
      [t.deployment_value]:
        t.target_native_name || t.target_lang_name || t.target_lang_code,
    }));

  const deploymentToLangCodeMap = translations.reduce(
    (acc, t) => {
      return { ...acc, [t.deployment_value]: t.target_lang_code };
    },
    {} as Record<string, string>
  );

  if (includeFlags) {
    const labelsWithFlags = labels.map((l) => {
      const [deploymentValue, label] = Object.entries(l)[0];
      return {
        [deploymentValue]:
          flags[
            deploymentToLangCodeMap[deploymentValue] as keyof typeof flags
          ] + label,
      }; //add flag
    });
    if (!includeRegion) {
      return labelsWithFlags.map((l) => {
        const [deploymentValue, label] = Object.entries(l)[0];
        return { [deploymentValue]: label.replace(/\s*[（(].*$/, '') }; //remove region
      });
    }
    return labelsWithFlags;
  } else {
    if (!includeRegion) {
      return labels.map((l) => {
        const [deploymentValue, label] = Object.entries(l)[0];
        return { [deploymentValue]: label.replace(/\s*[（(].*$/, '') }; //remove region
      });
    }
    return labels;
  }
};

export const SelectorContentCustomization = () => {
  const { project } = useCurrentProjectData();

  const [loading, setLoading] = useState(false);
  const [showLabelEditor, setShowLabelEditor] = useState(false);

  const translation = project?.translations?.find(
    (t) => t.translation_key === project.yy_translation_key
  );
  const translationConfig = (() => {
    try {
      return JSON.parse(hexToAscii(translation?.translation_config ?? ''));
    } catch {
      return {};
    }
  })();

  const translations = project?.translations ?? ([] as TranslationKey[]);

  const deploymentToTranslationMap = translations.reduce(
    (acc, t) => {
      return { ...acc, [t.deployment_value]: t };
    },
    {} as Record<string, TranslationKey>
  );

  const existingLabels =
    translationConfig?.translation_rules?.language_selector_labels ?? [];
  const activeOldLabels = existingLabels.filter((l: Record<string, string>) => {
    const translationForLanguage =
      deploymentToTranslationMap[
        Object.keys(l)[0] as keyof typeof deploymentToTranslationMap
      ] ?? ({} as TranslationKey);
    return translationForLanguage.is_active && translationForLanguage.is_live;
  });

  const labels =
    Object.keys(activeOldLabels).length > 0
      ? activeOldLabels
      : translations
          ?.filter(
            (t) => t.target_lang_code !== 'YY' && t.is_active && t.is_live
          )
          .map((t) => ({
            [t.deployment_value]:
              t.target_native_name || t.target_lang_name || t.target_lang_code,
          }));

  const updateSelector = (
    newTranslationConfig: string,
    showFlag: boolean,
    showRegion: boolean
  ) => {
    setLoading(true);
    Xapis.ProjectTranslation.put(
      project.project_key,
      project.yy_translation_key,
      {
        translation_config: asciiToHex(newTranslationConfig),
        translation_config_hash: translation?.translation_config_hash ?? '',
      }
    )
      .then(({ status }) => {
        if (isSuccessStatus(status)) {
          setShowFlag(showFlag);
          setShowRegion(showRegion);
          notifications.show({
            message: 'Successfully updated your selector widget!',
          });
        } else {
          notifications.show({
            message: 'Oops, there was a problem with saving your edits.',
          });
        }
      })
      .catch(() => {
        notifications.show({
          message: 'Unable to update selector widget at this time.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFlagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const newTranslationConfig = {
      ...translationConfig,
      translation_rules: {
        ...translationConfig.translation_rules,
        language_selector_labels: generateLabels(
          project!.translations ?? [],
          checked,
          showRegion,
          false
        ),
      },
      staging_translation_rules: {
        ...translationConfig.translation_rules,
        language_selector_labels: generateLabels(
          project!.translations ?? [],
          checked,
          showRegion,
          true
        ),
      },
    };
    updateSelector(JSON.stringify(newTranslationConfig), checked, showRegion);
  };
  const handleShowRegionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const newTranslationConfig = {
      ...translationConfig,
      translation_rules: {
        ...translationConfig.translation_rules,
        language_selector_labels: generateLabels(
          project!.translations ?? [],
          showFlag,
          checked,
          false
        ),
      },
      staging_translation_rules: {
        ...translationConfig.translation_rules,
        language_selector_labels: generateLabels(
          project!.translations ?? [],
          showFlag,
          checked,
          true
        ),
      },
    };
    updateSelector(JSON.stringify(newTranslationConfig), showFlag, checked);
  };

  const exampleLabel = labels ? (Object.values(labels[0])[0] as string) : '';
  const flagRegExp = /\p{So}\p{So}/gu;
  const regionRegExp = /\s*[（(].*$/;

  const [showFlag, setShowFlag] = useState<boolean>(
    labels ? flagRegExp.test(exampleLabel) : false
  );
  const [showRegion, setShowRegion] = useState<boolean>(
    labels ? regionRegExp.test(exampleLabel) : true
  );

  return (
    <Flex mt={10} direction="column" gap={10}>
      <Text component="h1">Label Options</Text>
      <Flex>
        <Switch
          checked={showFlag}
          onChange={handleFlagChange}
          label={
            <Text style={{ cursor: 'pointer' }} fz={'sm'} lh={'sm'}>
              Display Flag Icons
            </Text>
          }
          classNames={
            !loading
              ? {
                  input: classes.switchInput,
                  track: classes.switchTrack,
                }
              : {}
          }
          disabled={loading}
        />
      </Flex>
      <Flex>
        <Switch
          checked={showRegion}
          onChange={handleShowRegionChange}
          label={
            <Text style={{ cursor: 'pointer' }} fz={'sm'} lh={'sm'}>
              Display Region
            </Text>
          }
          classNames={
            !loading
              ? {
                  input: classes.switchInput,
                  track: classes.switchTrack,
                }
              : {}
          }
          disabled={loading}
        />
      </Flex>
      <Button
        pl={0}
        variant="transparent"
        justify="flex-start"
        onClick={() => setShowLabelEditor(!showLabelEditor)}
        rightSection={
          showLabelEditor ? (
            <HiChevronDown size={20} />
          ) : (
            <HiChevronRight size={20} />
          )
        }
      >
        Customize by language
      </Button>
      {showLabelEditor && <SelectorLabelEditor labels={labels} />}
    </Flex>
  );
};
