import React, { useMemo } from 'react';
import { Flex, Grid, Text, useMantineTheme } from '@mantine/core';
import AlertRed from 'images/icons/AlertRed.svg';
import CheckmarkGreen from 'images/icons/CheckmarkGreen.svg';
import WarningIconYellow from 'images/icons/WarningIconYellow.svg';
import { WordsServedResponse } from 'store';
import moment from 'moment';
import { WordsServedWithRemaining } from '../types';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  todaysDate: string;
  thresholdValue: number;
  monthlyWordsServed: WordsServedResponse[];
  monthlyWordsServedWithRemaining: WordsServedWithRemaining[];
};

const WordsServedUsageHeader = ({
  todaysDate,
  thresholdValue,
  monthlyWordsServed,
  monthlyWordsServedWithRemaining,
}: Props) => {
  const isMobileSize = useMediaQuery('(max-width: 575px)');
  const { colors } = useMantineTheme();

  const thresholdWarning = 99; // Important: Do not make this greater than 100
  const hasData = monthlyWordsServed.length > 0;
  const date = new Date();
  const currentNumOfDays = date.getDate();
  const totalNumOfDays = moment(date).daysInMonth() || 0;

  const mtdWordsServed = useMemo(() => {
    return monthlyWordsServed.reduce((accum, { count }) => accum + count, 0);
  }, [monthlyWordsServed]);

  const mtdWordsServedWithCommas =
    mtdWordsServed.toLocaleString('en-US') || '0';

  const mtdWordsServedPercentage =
    (thresholdValue && ((mtdWordsServed / thresholdValue) * 100).toFixed(2)) ||
    '0';

  const todaysTotalWordsServed = useMemo(() => {
    return Math.round(
      monthlyWordsServed.reduce((accum, { timeslice = '', count = 0 }) => {
        if (timeslice === todaysDate) {
          return accum + count;
        }
        return accum;
      }, 0)
    );
  }, [monthlyWordsServed, todaysDate]);

  const todaysTotalWordsServedWithCommas =
    todaysTotalWordsServed.toLocaleString('en-US') || '0';

  const todaysWordsServedPercentage =
    !todaysTotalWordsServed && !mtdWordsServed
      ? (
          (todaysTotalWordsServed / (mtdWordsServed - todaysTotalWordsServed)) *
          100
        ).toFixed(2)
      : '0';

  const projectedWordsServed =
    (
      Math.round(mtdWordsServed / currentNumOfDays) * totalNumOfDays
    ).toLocaleString('en-US') || '0';

  const logoPicker = () => {
    const percentage = parseFloat(
      ((mtdWordsServed / thresholdValue) * 100).toFixed(2)
    );

    if (percentage >= thresholdWarning && percentage < 100.0) {
      return WarningIconYellow;
    } else {
      return CheckmarkGreen;
    }
  };
  const projectedLabel = () => {
    let innerNode;
    // TODO: thresholdValue is returning 0 and causing "infinity" to show up. Redo this when the free tier is reworked on xapis
    if (thresholdValue === 0) {
      return null;
    }

    const projectedNumberType = parseInt(
      projectedWordsServed.replace(/,/g, ''),
      10
    );

    const percentage = parseFloat(
      (projectedNumberType / thresholdValue).toFixed(2)
    );

    if (percentage < 1.0) {
      innerNode = '(under threshold)';
    } else {
      innerNode = (
        <Text span>
          (
          <Text span style={(theme) => ({ color: theme.colors.text2[9] })}>
            {`${((projectedNumberType / thresholdValue) * 100).toFixed(2)}`}%
          </Text>
          &nbsp;of threshold)
        </Text>
      );
    }

    return (
      <Text fz={16} fw={400} ta="end" truncate>
        {innerNode}
      </Text>
    );
  };

  return (
    <Grid w="100%">
      <Grid.Col span={{ base: 12, xs: 4 }}>
        <Flex w="100%" direction="column" rowGap={10} pr="1rem">
          <Text fz={14} fw={700} truncate>
            MONTH TO DATE
          </Text>
          <Text
            fz={30}
            fw={700}
            display="inline-flex"
            style={{ columnGap: 5, justifyContent: 'flex-end' }}
            truncate
          >
            {mtdWordsServed > thresholdValue && (
              <img width={20} src={AlertRed} alt="icon" />
            )}
            <Text fz={30} fw={700} truncate span>
              {mtdWordsServedWithCommas}
            </Text>
          </Text>
          <Text fz={16} fw={400} ta="right" truncate>
            (
            <Text
              span
              style={(theme) => ({
                color:
                  mtdWordsServed > thresholdValue ? theme.colors.text2[9] : '',
              })}
            >
              {mtdWordsServedPercentage}%
            </Text>
            &nbsp;of monthly limit)
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col
        span={{ base: 12, xs: 4 }}
        px={{ base: 5, md: 'auto' }}
        style={
          isMobileSize
            ? {
                borderTop: `2px solid ${colors.divider[0]}`,
                borderBottom: `2px solid ${colors.divider[0]}`,
              }
            : {
                borderLeft: `2px solid ${colors.divider[0]}`,
                borderRight: `2px solid ${colors.divider[0]}`,
              }
        }
      >
        <Flex w="100%" direction="column" px="1rem" rowGap={10}>
          <Text fz={14} fw={700} truncate>
            TODAY
          </Text>
          <Text fz={30} fw={700} ta="end" truncate>
            {hasData ? `+${todaysTotalWordsServedWithCommas}` : 'n/a'}
          </Text>
          {hasData && (
            <Text fz={16} fw={400} ta="end" truncate>
              (
              <Text
                span
                style={(theme) => ({
                  color: theme.colors.text[5],
                })}
              >
                +{todaysWordsServedPercentage}%
              </Text>
              &nbsp;change)
            </Text>
          )}
        </Flex>
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 4 }}>
        <Flex w="100%" direction="column" rowGap={10} pl="1rem">
          <Text fz={14} fw={700} ta="start" truncate>
            PROJECTED TOTAL
          </Text>
          <Text
            fz={30}
            fw={700}
            display="inline-flex"
            style={{ columnGap: 5, justifyContent: 'flex-end' }}
          >
            {hasData && mtdWordsServed < thresholdValue && (
              <img width={20} src={logoPicker()} alt="icon" />
            )}
            <Text fz={30} fw={700} truncate span>
              {' '}
              {monthlyWordsServedWithRemaining.length
                ? projectedWordsServed
                : 'n/a'}
            </Text>
          </Text>
          {hasData && projectedLabel()}
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default WordsServedUsageHeader;
