import React, { useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  Slider,
  Stack,
  StackProps,
  Text,
  NumberInput,
  useMantineTheme,
  Tooltip,
} from '@mantine/core';
import { InfoIcon } from '../../../../../StaticIcons';
import { RiDivideLine, RiEqualLine } from 'react-icons/ri';
import { FaTimes } from 'react-icons/fa';
import { HiPencil } from 'react-icons/hi';

type Props = {
  monthlyPageViews: number;
  estimatedWordsServed: number;
  analyzedWords: string;
  analyzedPages: string;
  averageWordsPerPage: number;
  translatedTraffic: number;
  setTranslatedTraffic: React.Dispatch<React.SetStateAction<number>>;
  setAveragePageWords: React.Dispatch<React.SetStateAction<number>>;
  setPageViewsMonthly: React.Dispatch<React.SetStateAction<number>>;
  stackProps?: StackProps;
};

const EstimateCalculation = ({
  monthlyPageViews,
  estimatedWordsServed,
  analyzedWords,
  analyzedPages,
  averageWordsPerPage,
  translatedTraffic,
  setTranslatedTraffic,
  setAveragePageWords,
  setPageViewsMonthly,
  stackProps,
}: Props) => {
  const colors = useMantineTheme().colors;

  const [editAverageWords, setEditAverageWords] = useState(false);
  const [editMonthlyPageViews, setEditMonthlyPageViews] = useState(false);

  return (
    <Stack w="100%" py="3rem" px="1rem" gap="1.5rem" {...stackProps}>
      <Flex justify="space-between" columnGap="1rem">
        <Text fw={500}>Analyzed Words</Text>
        <Text fw={700}>{analyzedWords}</Text>
      </Flex>
      <Flex justify="space-between" columnGap="2rem">
        <Text fw={500}>Analyzed Pages</Text>
        <Flex columnGap="0.75rem" align="center">
          <RiDivideLine
            color={colors.text[8]}
            style={{ minWidth: 22 }}
            size={22}
            strokeWidth={1.5}
          />
          <Text fw={700}>{analyzedPages}</Text>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" color={colors.divider[0]} />
      <Flex pl="2rem" justify="space-between" columnGap="2rem">
        <Text fw={400}>Average Words per Page</Text>
        <Flex columnGap="0.75rem" align="center">
          <RiEqualLine
            color={colors.text[8]}
            style={{ minWidth: 22 }}
            size={22}
            strokeWidth={1.5}
          />
          {editAverageWords ? (
            <NumberInput
              value={averageWordsPerPage}
              onChange={(value) =>
                setAveragePageWords && setAveragePageWords(Number(value))
              }
              allowNegative={false}
              min={0}
              fw={700}
              maw={150}
            />
          ) : (
            <Text fw={700}>{averageWordsPerPage}</Text>
          )}
          <HiPencil
            style={{ cursor: 'pointer' }}
            size="1.5rem"
            color="#0071DB"
            onClick={() => setEditAverageWords(!editAverageWords)}
          />
        </Flex>
      </Flex>
      <Flex justify="space-between" columnGap="2rem">
        <Flex gap={1}>
          <Text fw={500}>Monthly Pageviews&nbsp;</Text>
          <Tooltip
            label="Monthly page views are obtained from a third party web traffic data provider
                  and are not guaranteed to be accurate. You should compare against your own internal data to be sure."
          >
            <Box display="inline-block">
              <InfoIcon fill={colors.icon[0]} size={15} />
            </Box>
          </Tooltip>
        </Flex>
        <Flex columnGap="0.75rem" align="center">
          <FaTimes color={colors.text[8]} style={{ minWidth: 18 }} size={18} />
          {editMonthlyPageViews ? (
            <NumberInput
              value={monthlyPageViews}
              onChange={(value) =>
                setPageViewsMonthly && setPageViewsMonthly(Number(value))
              }
              allowNegative={false}
              fw={700}
              maw={150}
            />
          ) : (
            <Text fw={700}>{monthlyPageViews}</Text>
          )}
          <HiPencil
            style={{ cursor: 'pointer' }}
            size="1.5rem"
            color="#0071DB"
            onClick={() => setEditMonthlyPageViews(!editMonthlyPageViews)}
          />
        </Flex>
      </Flex>
      <Flex justify="space-between" columnGap="2rem">
        <Flex gap={1}>
          <Text display="inline" fw={500}>
            Translated Traffic&nbsp;
          </Text>
          <Tooltip
            label="This number is an estimate and represents what percentage of your total web traffic would choose
                  a translated language if it were available. You can edit it up or down based on your own estimate."
          >
            <Box display="inline-block">
              <InfoIcon fill={colors.icon[0]} size={15} />
            </Box>
          </Tooltip>
        </Flex>
        <Flex style={{ flex: 1 }} columnGap="1.25rem" align="center">
          <Flex w="100%" columnGap="0.5rem" align="center" justify="flex-end">
            <FaTimes
              color={colors.text[8]}
              style={{ minWidth: 18 }}
              size={18}
            />
            <Slider
              w="100%"
              maw="6.5rem"
              onChange={setTranslatedTraffic}
              value={translatedTraffic}
              label={(value: number) => `${value}%`}
            />
          </Flex>
          <Text fw={700}>{translatedTraffic}%</Text>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" color={colors.divider[0]} />
      <Text
        fw={700}
        fz="1.75rem"
        lh="1.75rem"
        ta="center"
        style={{ textWrap: 'wrap' }}
      >
        {estimatedWordsServed.toLocaleString()} /&nbsp;
        <Text fz="0.9rem" fw={700} lh="0.9rem" ta="center" span>
          Monthly Words Served
        </Text>
      </Text>
    </Stack>
  );
};

export default EstimateCalculation;
