import React from 'react';
import { Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { xapisEnv } from 'store';
import { TbExternalLink } from 'react-icons/tb';
import CustomModal from '../../../CustomModal';
import { GLWEB_SUPPORT_URL } from 'helpers';

const LearnMoreExternalLink = () => (
  <Link
    to={`${GLWEB_SUPPORT_URL}/article/130-what-are-the-different-deployment-methods`}
    target="_blank"
  >
    <Text
      td="underline"
      c="text.8"
      fz="1.15rem"
      fw={600}
      display="inline-flex"
      span
    >
      Learn more.
      <TbExternalLink size={24} />
    </Text>
  </Link>
);

export default function UpgradeToPlusModal({
  opened,
  setOpened,
}: {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { glgoMarketingUrl = '' } = xapisEnv.getHost;
  const contactUsUrl = `${glgoMarketingUrl}/about-us#contact-us-form`;

  return (
    <>
      <CustomModal
        size="lg"
        title="Upgrade to gain access to this feature!"
        opened={opened}
        onClose={() => setOpened(false)}
        footerActions={[
          { label: 'Contact Us', to: contactUsUrl, target: '_blank' },
        ]}
      >
        <Text fz="1.15rem">
          You will need to upgrade to a higher service tier to gain access to
          different deployment methods, such as Folders or Sub-Domains.{' '}
          <LearnMoreExternalLink />
          <br />
          <br />
          These other deployment methods are necessary to enable multi-lingual
          SEO. Our team will help you get started.
        </Text>
      </CustomModal>
    </>
  );
}
