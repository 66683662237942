import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Container,
  Flex,
  Grid,
  ScrollArea,
} from '@mantine/core';
import { BasicHorizontalDivider, SearchBar } from '../../../Generic';
import { useCurrentProjectData } from 'store';
import { getYYTarget } from 'helpers';
import { useSubmit } from 'react-router-dom';
import { useGLGOParams } from 'hooks';
import classes from './AddLanguages.module.css';
import { DOMAIN, FOLDER } from '../deployment/utils';

type Props = {
  showSpecifyModal: boolean;
  setShowSpecifyModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddLanguagesModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTranslations: Partial<LanguageCode>[];
  setSelectedTranslations: React.Dispatch<
    React.SetStateAction<Partial<LanguageCode>[]>
  >;
  availableTranslations: Partial<LanguageCode>[];
};
export const AddLanguages = ({
  setShowAddLanguagesModal,
  availableTranslations,
  setShowSpecifyModal,
  selectedTranslations,
  setSelectedTranslations,
}: Props) => {
  const submit = useSubmit();
  const { projectKey = '' } = useGLGOParams();
  const { project: { translations = [], deployment_method = '' } = {} } =
    useCurrentProjectData();

  const yyTarget = getYYTarget(translations);

  const [searchText, setSearchText] = useState('');
  const [prevSearchText, setPrevSearchText] = useState('');
  const [filteredTranslations, setFilteredTranslations] = useState<
    Partial<LanguageCode>[]
  >(availableTranslations);

  useEffect(() => {
    if (searchText.length === 0) {
      setFilteredTranslations(availableTranslations);
      setPrevSearchText('');
    }
    if (searchText.length !== prevSearchText.length) {
      const filteredTranslationsBySearch = availableTranslations.filter(
        ({ langcode_name }) => {
          return (
            langcode_name?.includes(searchText) ||
            langcode_name?.toLowerCase()?.includes(searchText)
          );
        }
      );
      setFilteredTranslations(filteredTranslationsBySearch);
    }
  }, [searchText, prevSearchText, availableTranslations]);

  useEffect(() => {
    setFilteredTranslations(availableTranslations);
  }, [availableTranslations]);

  const handleSelectedTranslation = (selectedTarget: Partial<LanguageCode>) => {
    const prevTargetNames = selectedTranslations.map(
      ({ langcode_name }) => langcode_name
    );
    const isSelected = prevTargetNames?.includes(selectedTarget?.langcode_name);

    if (isSelected) {
      const filteredSelectedTargets = selectedTranslations.filter(
        ({ langcode_name }) => {
          return langcode_name !== selectedTarget?.langcode_name;
        }
      );
      setSelectedTranslations(filteredSelectedTargets);
    } else {
      setSelectedTranslations((prevSelectedTargets) => [
        ...prevSelectedTargets,
        selectedTarget,
      ]);
    }
  };

  return (
    <Flex
      w="100%"
      h="100%"
      rowGap="1rem"
      direction="column"
      px="0.5rem"
      pt={0}
      pb="0.75rem"
    >
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        justify={{ sm: 'space-between' }}
        columnGap="0.5rem"
        rowGap="1rem"
      >
        <SearchBar
          maxWidth="none"
          searchText={searchText}
          setSearchText={setSearchText}
          setPrevSearchText={setPrevSearchText}
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const hasEmptyValues =
              deployment_method === DOMAIN || deployment_method === FOLDER;

            if (hasEmptyValues) {
              setShowSpecifyModal(true);
              setShowAddLanguagesModal(false);
            } else {
              submit(
                {
                  projectKey,
                  yyTarget: JSON.stringify(yyTarget),
                  selectedTranslations: JSON.stringify(selectedTranslations),
                  deployment_method,
                },
                {
                  method: 'post',
                  encType: 'application/json',
                }
              );
            }
          }}
        >
          <Button
            w={{ base: '100%', sm: 'auto' }}
            data-testid="pw-modal-add-language-button"
            type="submit"
            disabled={selectedTranslations.length === 0}
          >
            Add
          </Button>
        </form>
      </Flex>
      <Card pl={30} pr={0} mih={0} flex={1}>
        <ScrollArea type="always" offsetScrollbars scrollbarSize={18}>
          {filteredTranslations?.map((target, key) => {
            const translationFound: Partial<LanguageCode> | undefined =
              selectedTranslations?.find(
                ({ langcode_code }) => langcode_code === target?.langcode_code
              );
            return (
              <div key={target?.langcode_code || key}>
                <Grid align="center" py={15}>
                  <Flex
                    align="center"
                    m={0}
                    onClick={() => handleSelectedTranslation(target)}
                    className={classes.handleSelectWrapper}
                    w="100%"
                  >
                    <Grid.Col span={2}>
                      <Checkbox
                        data-testid={`pw-modal-language-checkbox-${key}`}
                        styles={({ colors }) => ({
                          icon: {
                            marginLeft: 11.5,
                            width: '40%',
                          },
                          input: {
                            borderRadius: 3,
                            border: `2px solid ${colors.icon[2]}`,
                            height: 24,
                            marginLeft: 5,
                            width: 24,
                          },
                        })}
                        readOnly
                        checked={translationFound !== undefined}
                      />
                    </Grid.Col>
                    <Grid.Col fw={600} fz={16} span={8}>
                      <Container
                        data-testid={`pw-language-modal-index-${key}`}
                        p={0}
                        style={({ colors }) => ({
                          color: colors.text[2],
                          overflowWrap: 'anywhere',
                        })}
                      >
                        {target.langcode_name}
                      </Container>
                    </Grid.Col>
                  </Flex>
                </Grid>
                <BasicHorizontalDivider mobileMargin="0" />
              </div>
            );
          })}
        </ScrollArea>
      </Card>
    </Flex>
  );
};

export default AddLanguages;
