import React from 'react';
import { UnstyledButton, Text, Group, Tooltip } from '@mantine/core';
import { dashboardRC } from 'helpers';
import { Link } from 'react-router-dom';
import { MRT_RowData } from 'mantine-react-table';

export const getEditDeleteColumn = (
  EditAction: ({ data }: { data: MRT_RowData }) => JSX.Element,
  DeleteAction: ({ data }: { data: MRT_RowData }) => JSX.Element
) => {
  return {
    accessorKey: '',
    header: '',
    id: 'actions',
    size: 80,
    Cell: ({ row }: MRT_RowData) => (
      <Group gap={10} pr={20} align="center" wrap="nowrap">
        <EditAction data={row.original} />
        <DeleteAction data={row.original} />
      </Group>
    ),
    enableSorting: false,
    grow: false,
  };
};

export const getBasicColumn = (
  key: string,
  header?: string,
  withTooltip?: boolean
) => {
  return {
    ...(withTooltip && {
      Cell: ({ cell }: MRT_RowData) => (
        <Tooltip label={cell.getValue()}>
          <Text data-value={cell.getValue()} truncate>
            {cell.getValue()}
          </Text>
        </Tooltip>
      ),
    }),
    accessorKey: key,
    header: header ?? key,
  };
};

export const getEndDate = () => {
  const today = new Date();
  // Metrics data isn't present for the current day, so we instead only show data up until yesterday
  today.setUTCDate(today.getUTCDate() - 1);
  return today;
};

// We want to always start at the first of the month, rather than the last day of last month
export const getMonthToDateTimeSlice = () => {
  const today = new Date();
  const endDate = getEndDate();
  // If it's the first of the month, we want to show no data (because XAPIS doesn't have any yet), which will happen with 0 day
  if (today.getUTCMonth() !== endDate.getUTCMonth()) {
    // This prevents last day of month, ex. 11/01/2023 would show '31 day'
    return '0 day';
  }
  return `${endDate.getUTCDate()} day`;
};

export const getTimeSliceOptions = () => {
  const timeSliceOptions = [
    { label: 'Month to Date', value: getMonthToDateTimeSlice() },
    { label: 'Past month', value: '1 month' },
    { label: 'Past year', value: '1 year' },
  ];
  const isSevenDays = getMonthToDateTimeSlice() === '7 day';
  const pastSevenDaysTimeSlice = {
    label: 'Past 7 days',
    value: '7 day',
  };
  if (!isSevenDays) {
    timeSliceOptions.splice(1, 0, pastSevenDaysTimeSlice);
  }
  return timeSliceOptions;
};

export const getStatusBannerBody = (
  isSubscriptionExpired: boolean,
  isFreeTrial: boolean,
  activeProjectStatus: string,
  setShowGoLiveModal: React.Dispatch<React.SetStateAction<boolean>>,
  projectId: string,
  threshold: number = 0
) => {
  switch (true) {
    case isSubscriptionExpired:
      return (
        <Text fw={500}>
          Your {isFreeTrial ? 'free trial' : 'subscription'} has expired. Click{' '}
          <Link
            to={`/${projectId}/${dashboardRC.PAGE_ACCOUNT}/billing-information/manage-subscription${threshold > 0 ? `?threshold=${threshold}` : ''}`}
          >
            <Text span td="underline" fw={500}>
              here
            </Text>
          </Link>{' '}
          to renew it!
        </Text>
      );
    case activeProjectStatus === 'UNVERIFIED':
      return (
        <Text fw={500}>
          Your project is unverified.&nbsp;
          <Link to={'/' + dashboardRC.PAGE_CREATE_PROJECT}>
            <Text td="underline" span>
              Complete your setup here.
            </Text>
          </Link>
        </Text>
      );
    case activeProjectStatus !== 'LIVE':
      return (
        <Text>
          <Text span>Your project is in&nbsp;</Text>
          <Link
            style={{ textDecoration: 'none' }}
            to={`/${projectId}/${dashboardRC.PAGE_SETTINGS}/injection`}
          >
            <UnstyledButton>
              <Text span td="underline">
                private mode
              </Text>
            </UnstyledButton>
          </Link>
          <Text span>. Click</Text>&nbsp;
          <UnstyledButton
            onClick={() => setShowGoLiveModal(true)}
            td="underline"
          >
            <Text span td="underline">
              here
            </Text>
          </UnstyledButton>
          <Text span>&nbsp;to go live!</Text>
        </Text>
      );
    default:
      return null;
  }
};
