import React from 'react';
import PlanInformation from './plan-information/PlanInformation';
import { BasicHorizontalDivider } from '../../Generic';
import BillingHistory from './BillingHistory';
import {
  BillingLoaderData,
  useBillingQueries,
  useCurrentProjectData,
  xapisEnv,
} from 'store';
import { useGLGOParams } from 'hooks';
import { useRouteLoaderData } from 'react-router-dom';

export const BillingInformation = () => {
  const { origin_name, shouldQueryQuickQuote } = useRouteLoaderData(
    'billing'
  ) as BillingLoaderData;
  const { projectKey = '' } = useGLGOParams();
  const subscription_status =
    useCurrentProjectData().project.subscription_status;
  const { payKey } = xapisEnv.getHost;

  const { subscriptions, billingHistory, skus, sourceData, quickQuote } =
    useBillingQueries({
      payKey,
      projectKey,
      isExpired: subscription_status === 'expired',
      shouldQueryQuickQuote,
      origin_name,
    });
  const default_source_id = sourceData.default_source_id;

  const { price = 0 } = subscriptions[0] || {};
  const isPaidPlan = subscriptions.length > 0 && price !== 0;

  return (
    <>
      <PlanInformation
        skus={skus}
        subscriptions={subscriptions}
        sources={sourceData.payment_sources}
        default_source_id={default_source_id}
        quickQuote={quickQuote}
      />
      {isPaidPlan && (
        <>
          <BasicHorizontalDivider borderWidth={2} mobileMargin="0 auto" />
          <BillingHistory billingHistory={billingHistory} />
        </>
      )}
    </>
  );
};
