import { Box, Card, Text } from '@mantine/core';
import React from 'react';
import { compactFormatter } from 'helpers';
import moment from 'moment/moment';

type Props = {
  active?: boolean;
  payload?: Array<Record<string, string>>;
  label?: string;
};

export const CustomBarChartTooltip = ({ active, payload, label }: Props) => {
  const formatChartDateLabel = (date: string) => {
    if (date.length <= 7) {
      return moment(date).format('MMMM YYYY') || date;
    } else {
      return moment(date).format('MMMM D, YYYY') || date;
    }
  };

  const value = (payload && compactFormatter(Number(payload[0]?.value))) || 0;

  if (value && active && payload && payload.length) {
    return (
      <Card
        p={10}
        shadow="sm"
        bg="background.0"
        style={{
          borderRadius: 5,
        }}
      >
        <Text fz={14} fw={600} c="text.2">
          {formatChartDateLabel(label || '')}
        </Text>
        <Box mt={10}>
          {payload &&
            payload.map((data, index) => {
              const language = data['name']
                ? data['name'].slice(0, data['name'].indexOf('.'))
                : '';
              const wordsServed = data['value']?.toLocaleString() || '';
              return (
                <Text
                  key={language ? `${language}-${index}` : index}
                  fz={14}
                  fw={400}
                  c="text.2"
                >
                  {language && wordsServed ? language + ': ' : ''}
                  {language && wordsServed ? (
                    <Text span fw={700}>
                      {wordsServed}
                    </Text>
                  ) : (
                    ''
                  )}
                </Text>
              );
            })}
        </Box>
      </Card>
    );
  }
  return null;
};

export default CustomBarChartTooltip;
