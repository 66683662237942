import React from 'react';
import { Box, Button, Card, Flex, Text, Title } from '@mantine/core';
import { OrderConfirmationLoaderData } from 'store';
import { ConfirmEnvelope } from 'images';
import confirmBg from './ConfirmBG.svg';
import { useLoaderData, useNavigation, Link } from 'react-router-dom';
import { useGLGOParams } from 'hooks';

export const OrderConfirmationPage = () => {
  const { currentOrderId, currentOrderThreshold } =
    useLoaderData() as OrderConfirmationLoaderData;
  const { projectId } = useGLGOParams();
  const { state } = useNavigation();
  const loading = state !== 'idle';
  const noOrderFound = currentOrderId.length === 0;

  return (
    <Flex
      mih="calc(100vh - 48px)"
      pt="2rem"
      pb="4rem"
      justify="center"
      direction="column"
      style={{
        backgroundImage: `url(${confirmBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Card
        maw={{ base: '95%', sm: '80%', md: '60%' }}
        mx="auto"
        p={{ base: '2rem 1rem', xs: '2rem', sm: '3rem' }}
        radius="md"
        shadow="sm"
        withBorder
      >
        <Flex direction="column" align="center" gap={noOrderFound ? 10 : 0}>
          {noOrderFound ? (
            <Title order={1} mb="0.25rem" ta="center">
              No Order Found
            </Title>
          ) : (
            <Box opacity={loading ? 0.2 : 1}>
              <Title order={1} mb="0.25rem" ta="center">
                Thank You For Choosing GlobalLink WEB!
              </Title>
              <Title order={3} mb="1rem" ta="center">
                Order #{currentOrderId}
              </Title>
              <Flex justify="center">
                <ConfirmEnvelope name="envelope" />
              </Flex>
              <Text
                ta="center"
                m={{ base: '1rem', sm: '2rem', lg: '3rem' }}
                size="md"
              >
                We're activating your subscription for{' '}
                {currentOrderThreshold.toLocaleString()} words per month. You'll
                receive a confirmation email once your payment has processed.
                This should only take a few minutes. Remember to check your spam
                folder if you do not see the email at first.
              </Text>
              <Text
                ta="center"
                mx={{ base: '1rem', sm: '2rem', lg: '3rem' }}
                mb="2rem"
                size="md"
              >
                If you don’t receive a confirmation email after 24 hours,
                contact us for support at: support@globalinkgo.com.
              </Text>
            </Box>
          )}
          <Link to={`/${projectId}/dashboard`}>
            <Button>Back to Dashboard</Button>
          </Link>
        </Flex>
      </Card>
    </Flex>
  );
};
