import React from 'react';
import { Text } from '@mantine/core';
import CustomModal from '../../../CustomModal';
import { notifications } from '@mantine/notifications';
import { useProjectsStore, ProjectStore } from 'store';
import { useRevalidator } from 'react-router-dom';

type Props = {
  opened: boolean;
  setOpened: (value: ((prevState: boolean) => boolean) | boolean) => void;
  activeProjectKey: string;
};

const GoLiveModal = ({ opened, setOpened, activeProjectKey }: Props) => {
  const { updateProject } = useProjectsStore((state: ProjectStore) => state);
  const revalidator = useRevalidator();

  return (
    <CustomModal
      title="You're ready to GO global!"
      opened={opened}
      onClose={() => setOpened(false)}
      footerActions={[
        {
          label: 'GO',
          action: () => {
            updateProject(activeProjectKey, { project_status: 'LIVE' });
            setOpened(false);
            notifications.show({ message: 'Your project is now live!' });
            revalidator.revalidate();
          },
        },
      ]}
    >
      <Text fz="1.15rem" ta="center">
        When you set your project to Live the selector will become publicly
        visible and visitors to your website will be able to see your translated
        website in the languages you've chosen.
      </Text>
    </CustomModal>
  );
};

export default GoLiveModal;
