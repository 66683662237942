import React from 'react';
import {
  Accordion,
  Anchor,
  Box,
  Card,
  Divider,
  Flex,
  Text,
} from '@mantine/core';
import { MdEdit, MdOutlineArrowDropDown } from 'react-icons/md';
import ChangeMethodModal from './ChangeMethodModal';
import ConfirmMethodModal from './ConfirmMethodModal';
import MethodsSwitch from './MethodsSwitch';
import { useDeploymentContext } from './DeploymentContextProvider';
import { useCurrentProjectType } from 'hooks';
import ChangeMethodButton from './ChangeMethodButton';
import { GLWEB_SUPPORT_URL } from 'helpers';

const Deployment = () => {
  const { isGLGOProject } = useCurrentProjectType();

  const {
    showForm,
    setShowForm,
    accordionValue,
    setAccordionValue,
    showChangeMethodModal,
    showConfirmMethodModal,
    selectedMethod,
    activeProject: { deployment_method: defaultMethod = '' } = {},
  } = useDeploymentContext();

  const methodType = showForm
    ? 'edit'
    : selectedMethod && !showChangeMethodModal && !showConfirmMethodModal
      ? 'configure'
      : 'default';
  const isConfigureType = methodType === 'configure';
  const isAccordionOpen = accordionValue === 'details';
  const shouldUpgradeToPlus = isGLGOProject && defaultMethod === 'cookie';

  return (
    <>
      <ConfirmMethodModal />
      <ChangeMethodModal />
      <MethodsSwitch methodType={isConfigureType ? methodType : null} />
      {!isConfigureType && (
        <Card maw="44rem" p="2rem" mt={16}>
          <Flex rowGap="1.5rem" direction="column">
            <Flex w="100%" align="center" columnGap="0.75rem">
              <Text fz="1.25rem" fw={700} tt="uppercase">
                Deployment Methods
              </Text>
            </Flex>
            <Divider size="xs" orientation="horizontal" color="divider.1" />
            <Text fz="1.25rem">
              There are four ways to deploy GlobalLink WEB.{' '}
              <Anchor
                fz="1.25rem"
                td="underline"
                c="text.8"
                fw={700}
                target="_blank"
                href={`${GLWEB_SUPPORT_URL}/article/130-what-are-the-different-deployment-methods`}
              >
                Click here
              </Anchor>{' '}
              to learn about the different deployment methods.
            </Text>
            <Flex justify="space-between" columnGap="1rem" align="center">
              <Text fz="1.25rem" fw={600}>
                Your current deployment method:&nbsp;&nbsp;&nbsp;
                <Text
                  display="inline-block"
                  fz="1.25rem"
                  c="text.5"
                  fw={700}
                  tt="capitalize"
                  span
                >
                  {defaultMethod}
                </Text>
              </Text>
              <ChangeMethodButton shouldUpgradeToPlus={shouldUpgradeToPlus} />
            </Flex>
            <Accordion
              styles={{
                content: {
                  padding: 0,
                },
                control: {
                  padding: 0,
                },
                item: {
                  borderBottom: 'none',
                },
              }}
              style={{ borderBottom: 'none' }}
              chevron={null}
              value={accordionValue}
              defaultValue={accordionValue}
              onChange={(e) => {
                if (showForm) setShowForm(false);
                setAccordionValue(e);
              }}
            >
              <Accordion.Item value="details">
                <Accordion.Control>
                  <Flex c="text.8" align="center" columnGap="0.5rem">
                    <MdOutlineArrowDropDown
                      size={34}
                      style={{
                        rotate: isAccordionOpen ? '0deg' : '-90deg',
                      }}
                    />
                    <Text c="text.8" fw={600} fz="1.25rem">
                      Details
                    </Text>
                    <Box
                      display={!isAccordionOpen || showForm ? 'none' : 'block'}
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowForm(true);
                      }}
                    >
                      <MdEdit size={22} />
                    </Box>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  <MethodsSwitch
                    methodType={isConfigureType ? null : methodType}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Flex>
        </Card>
      )}
    </>
  );
};

export default Deployment;
