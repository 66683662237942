import { useCurrentProjectData } from 'store';
import { hexToAscii } from 'helpers';
import { Avatar, Card, Flex, Group, ScrollArea, Text } from '@mantine/core';
import React, { ReactNode } from 'react';
import { FiMail, FiUsers } from 'react-icons/fi';
import { BasicHorizontalDivider } from '../../Generic';

export const MyContacts = ({ header }: { header: ReactNode }) => {
  const {
    project: { project_config: projectConfig },
  }: { project: ProjectKey } = useCurrentProjectData();

  const projectContacts =
    (projectConfig && JSON.parse(hexToAscii(projectConfig)).project_contacts) ||
    [];

  const contacts = projectContacts.map(
    ({
      image,
      avatar,
      title,
      email,
    }: {
      image: string;
      avatar: ReactNode;
      title: string;
      email: string;
    }) => (
      <div key={email}>
        <Group w="100%" wrap="nowrap" m={5}>
          <Avatar src={image} size="md">
            {!image && <span>{avatar}</span>}
          </Avatar>
          <Flex direction="column" flex="grow">
            <Text fz="sm" fw={500}>
              {title}
            </Text>
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Flex wrap="nowrap" gap={5} align="center">
                <FiMail />
                <Text fz="xs" fw={400}>
                  {email}
                </Text>
              </Flex>
            </a>
          </Flex>
        </Group>
        <BasicHorizontalDivider mobileMargin="0" />
      </div>
    )
  );

  return (
    <>
      <Group>{header}</Group>
      <Card h={217} pr={contacts.length > 0 ? 0 : undefined}>
        {contacts.length > 0 ? (
          <ScrollArea
            w="100%"
            h="100%"
            display="block"
            type="always"
            scrollbarSize={18}
            offsetScrollbars
          >
            {contacts}
          </ScrollArea>
        ) : (
          <Flex
            h="100%"
            justify="center"
            align="center"
            direction="column"
            gap={30}
            p="1rem"
          >
            <FiUsers size={55} />
            <Text ta="center">Team members will be added shortly</Text>
          </Flex>
        )}
      </Card>
    </>
  );
};

export default MyContacts;
