import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Flex, Button, Text, CloseButton } from '@mantine/core';
import { useGLGOParams } from 'hooks';
import {
  desktopRC,
  encodeKey,
  getNoTxRuleHash,
  isInContextWindow,
} from 'helpers';

import { NoTxRuleForm } from './NoTxRuleForm';
import { NoTranslateXRule, RulesFilter } from './NoTxEditor';
import { OpenContext } from '../../../desktop/OpenContext';
import { Header, SearchInput } from '../../../Generic';
import { IoMdAdd } from 'react-icons/io';
import { previewNoTxRule, resetPreview } from './contextFunctions';
import classes from './NoTxDashboard.module.css';

const isInContext = isInContextWindow();
const { addNoTxRule } = window.editorAPI || {};

const AddRuleIcon = (
  <Button variant="transparent" rightSection={<IoMdAdd size={20} />}>
    Add Rule
  </Button>
);
const AddRuleFromWebsiteIcon = (
  <Button variant="transparent">+ Add Rule:</Button>
);

export const NoTxDashboard = ({
  target,
  rules,
  setFilterBy,
  selectRule,
  filterBy,
  setLoading,
}: {
  target: TranslationKey | undefined;
  rules: NoTranslateXRule[];
  setFilterBy: React.Dispatch<React.SetStateAction<RulesFilter>>;
  selectRule?: (id: number) => void;
  filterBy: RulesFilter;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const [newRule, setNewRule] = useState<UX | null>(null);
  const { projectId = '' } = useGLGOParams();

  const toggleShowActive = () => {
    setFilterBy((prev) => ({ ...prev, showActive: !prev.showActive }));
  };
  const setTextFilter = (value: string) => {
    setFilterBy((prev) => ({ ...prev, text: value }));
  };
  const cancelAddRule = () => {
    resetPreview();
    setNewRule(null);
  };
  function onAddRule(selector: string) {
    const rule: UX = { X: selector };
    const id = getNoTxRuleHash(rule);
    selectRule && selectRule(id);
    previewNoTxRule(rule);
    setNewRule(rule);
  }

  useEffect(() => {
    if (!isInContext) return;
    // Set up event listener for adding rule from the website
    if (addNoTxRule) addNoTxRule(onAddRule);
  }, []);

  return (
    <Flex direction="column" rowGap="1rem" className="no-tx-dashboard">
      <Flex gap="1rem">
        <Header title="No Translate Rules" />
        <OpenContext
          path={`/${projectId}/${desktopRC.NO_TRANSLATE_RULES}/${encodeKey(target?.translation_key || '')}`}
          disabled={!target}
        />
      </Flex>
      {newRule ? (
        <Flex align={'center'} gap={4}>
          <NoTxRuleForm
            rules={rules}
            yyTarget={target}
            defaults={{ ...newRule, comment: '' }}
            icon={AddRuleFromWebsiteIcon}
            cleanup={() => setNewRule(null)}
            setLoading={setLoading}
          />
          <Text
            onClick={() => previewNoTxRule(newRule)}
            className={classes.newRule}
          >
            {newRule.X}
          </Text>
          <CloseButton onClick={cancelAddRule} />
        </Flex>
      ) : (
        <Flex align={'center'} justify={'space-between'} columnGap={10}>
          <Flex align="center" gap={20} flex={1} wrap={'wrap'}>
            <SearchInput
              searchText={filterBy.text}
              setSearchText={setTextFilter}
              maxWidth={400}
            />
            {isInContext && (
              <Button
                onClick={toggleShowActive}
                variant={filterBy.showActive ? 'default' : 'light'}
              >
                {filterBy.showActive ? 'Show All' : 'Show Active Rules'}
              </Button>
            )}
          </Flex>
          <Box style={{ alignSelf: 'flex-start' }}>
            <NoTxRuleForm
              rules={rules}
              yyTarget={target}
              icon={AddRuleIcon}
              setLoading={setLoading}
            />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
