import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import {
  Box,
  Flex,
  Tooltip,
  Image,
  Text,
  Card,
  LoadingOverlay,
  Title,
} from '@mantine/core';
import CaseSensitive from '../../gosetup/images/CaseSensitiveIcon.svg';
import OverrideMT from '../../gosetup/images/OverrideMTSymbol.svg';
import { MRT_ColumnDef } from 'mantine-react-table';
import {
  NEVER_TRANSLATE,
  NEVER_TRANSLATE_AS,
  RuleType,
  TRANSLATE_AS,
} from './glossaryConstants';
import TranslateAsIcon from '../../gosetup/images/TranslateAsGlossaryIcon.svg';
import NeverTranslateAsIcon from '../../gosetup/images/NeverTranslateAsGlossaryIcon.svg';
import NeverTranslateIcon from '../../gosetup/images/NeverTranslateGlossaryIcon.svg';
import CustomTable from '../../CustomTable';
import { getBasicColumn, getEditDeleteColumn } from '../../utils';
import { useViewSize } from 'hooks';
import { Xapis } from 'store';
import { isSuccessStatus } from 'helpers';
import { notifications } from '@mantine/notifications';
import TableDeleteButton from '../../TableDeleteButton';

export type GlossaryRow = {
  id: string;
  rule: string;
  terms: string;
  isCaseSensitive: string;
  isMt: string;
  languages: string;
  comment: string;
  manage: ReactElement;
  [key: string]: string | ReactElement;
};

type Props = {
  records: GlossaryRow[];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const GlossaryTable = ({ records, loading, setLoading }: Props) => {
  const { isMobileSize } = useViewSize();

  const icons: { [key in RuleType]: string } = {
    [TRANSLATE_AS]: TranslateAsIcon,
    [NEVER_TRANSLATE_AS]: NeverTranslateAsIcon,
    [NEVER_TRANSLATE]: NeverTranslateIcon,
  };

  const deleteGlossaryRule = (translation_key: string, ruleHash: string) => {
    setLoading(true);
    Xapis.Glossary.delete(translation_key, ruleHash)
      .then((res) => {
        if (isSuccessStatus(res.status)) {
          notifications.show({
            message: 'Glossary Rule Deleted.',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const columns: MRT_ColumnDef<GlossaryRow>[] = [
    {
      ...getBasicColumn('rule'),

      Cell: ({ cell }) => {
        const rule = cell.getValue() as RuleType;
        const ruleString =
          rule === NEVER_TRANSLATE_AS
            ? "Don't Translate As"
            : rule
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        return (
          <Tooltip label={ruleString}>
            <Flex
              data-value={ruleString}
              gap={8}
              direction="row"
              align={'center'}
            >
              <Box>
                <Image src={icons[rule]} miw={18} />
              </Box>
              <Text style={{ whiteSpace: 'inherit' }} truncate>
                {ruleString}
              </Text>
            </Flex>
          </Tooltip>
        );
      },
      size: 110,
      sortingFn: (rowA, rowB, columnId) =>
        (rowA.getValue(columnId) as string).localeCompare(
          rowB.getValue(columnId) as string
        ),
    },
    {
      ...getBasicColumn('terms'),
      size: 300,
      Cell: ({ cell }) => {
        const [source_text, targetTextString] = (
          cell.getValue() as string
        ).split(' as ');
        return (
          <Tooltip label={`${source_text + ' as ' + targetTextString}`}>
            <Text data-value={source_text} truncate>
              <Text truncate span td="underline" fw={700}>
                {source_text}
              </Text>
              {targetTextString && ' as '}
              <Text truncate span td="underline" fw={700}>
                {targetTextString}
              </Text>
            </Text>
          </Tooltip>
        );
      },
      sortingFn: (rowA, rowB, columnId) =>
        (rowA.getValue(columnId) as string).localeCompare(
          rowB.getValue(columnId) as string
        ),
    },
    {
      ...getBasicColumn('isCaseSensitive', 'CASE SENSITIVE'),
      Header: (
        <Tooltip label="CASE SENSITIVE">
          <Box>
            <Image src={CaseSensitive} />
          </Box>
        </Tooltip>
      ),
      size: 100,
    },
    {
      ...getBasicColumn('isMt', 'MT OVERRIDE'),
      Header: (
        <Tooltip label="MT OVERRIDE">
          <Box>
            <Image src={OverrideMT} />
          </Box>
        </Tooltip>
      ),
    },
    {
      ...getBasicColumn('languages', 'languages', true),
      Header: () => (
        <Tooltip label="Language">
          <Title order={2} tt="uppercase">
            Language
          </Title>
        </Tooltip>
      ),
    },
    {
      ...getBasicColumn('comment', 'notes', true),
      sortingFn: (rowA, rowB, columnId) =>
        (rowA.getValue(columnId) as string).localeCompare(
          rowB.getValue(columnId) as string
        ),
    },
    getEditDeleteColumn(
      ({ data }) => data.manage,
      ({ data }) => (
        <TableDeleteButton
          onClick={() =>
            deleteGlossaryRule(data.translation_key, data.source_text_hash)
          }
        />
      )
    ),
  ];

  const mobileColumns: MRT_ColumnDef<GlossaryRow>[] = [
    {
      ...getBasicColumn('rule'),
      Cell: ({ cell }) => {
        const rule = cell.getValue() as RuleType;
        const ruleString =
          rule === NEVER_TRANSLATE_AS
            ? "Don't Translate As"
            : rule
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        return (
          <Tooltip label={ruleString}>
            <Flex
              data-value={ruleString}
              gap={8}
              direction="row"
              align={'center'}
            >
              <Box>
                <Image src={icons[rule]} miw={18} />
              </Box>
              <Text style={{ whiteSpace: 'inherit' }} truncate>
                {ruleString}
              </Text>
            </Flex>
          </Tooltip>
        );
      },
      size: 110,
      sortingFn: (rowA, rowB, columnId) =>
        (rowA.getValue(columnId) as string).localeCompare(
          rowB.getValue(columnId) as string
        ),
    },
    {
      ...getBasicColumn('terms'),
      size: 200,
      Cell: ({ cell }) => {
        const [source_text, targetTextString] = (
          cell.getValue() as string
        ).split(' as ');
        return (
          <Tooltip
            label={`${source_text + ' as ' + targetTextString}`}
            position="top-start"
          >
            <Text data-value={source_text} truncate>
              <Text truncate span td="underline" fw={700}>
                {source_text}
              </Text>
              {targetTextString && ' as '}
              <Text truncate span td="underline" fw={700}>
                {targetTextString}
              </Text>
            </Text>
          </Tooltip>
        );
      },
      sortingFn: (rowA, rowB, columnId) =>
        (rowA.getValue(columnId) as string).localeCompare(
          rowB.getValue(columnId) as string
        ),
    },
    {
      ...getBasicColumn('languages', 'languages', true),
      Header: () => (
        <Tooltip label="Language">
          <Title order={2} tt="uppercase">
            Language
          </Title>
        </Tooltip>
      ),
    },
    getEditDeleteColumn(
      ({ data }) => data.manage,
      ({ data }) => (
        <TableDeleteButton
          onClick={() =>
            deleteGlossaryRule(data.translation_key, data.source_text_hash)
          }
        />
      )
    ),
  ];

  return (
    <Card p={0}>
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <CustomTable
        data={records}
        columns={isMobileSize ? mobileColumns : columns}
        rowVirtualizerOptions={{
          overscan: 50, //adjust the number of rows that are rendered above and below the visible area of the table
        }}
        bottomToolbarString={records.length === 1 ? 'Term' : 'Terms'}
      />
    </Card>
  );
};
