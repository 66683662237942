import { Button, Flex, Input, LoadingOverlay, Card } from '@mantine/core';
import { useMemo, useState } from 'react';
import { Header, getBasicColumn, getEditDeleteColumn } from 'ui';
import classes from './UserManagement.module.css';
import { MdSearch } from 'react-icons/md';
import * as React from 'react';
import { IoMdAdd } from 'react-icons/io';
import { useDisclosure } from '@mantine/hooks';
import UserManagementModal from './UserManagementModal';
import { useCurrentProjectData, useUserProvider, Xapis } from 'store';
import { failure, isSuccessStatus } from 'helpers';
import {
  GLGO_ADMIN,
  hasPageViewPermissions,
} from 'helpers/utils/projectPermissions';
import {
  defaultProjectUserPermission,
  ProjectUserPermission,
  TRANSPERFECT_ADMIN,
  TRANSPERFECT_USER,
  ADMIN,
} from 'helpers/utils/projectPermissions';
import CustomTable from 'ui/components/glgo/dashboard/CustomTable';
import { MRT_ColumnDef } from 'mantine-react-table';
import TableDeleteButton from 'ui/components/glgo/dashboard/TableDeleteButton';
import TableEditButton from 'ui/components/glgo/dashboard/TableEditButton';

export type UserManagementRowData = ProjectUserPermission & {
  name: string;
  roles: string;
  languages: string;
};

export type ModalAction = 'Add' | 'Edit';

export const UserManagementPage = () => {
  const { projectTranslationMap = {}, projectUserPermissionMap = {} } =
    useCurrentProjectData();

  const {
    xapisUser: { user_key: userKey },
  } = useUserProvider();

  const permissions = projectUserPermissionMap[userKey].rolePermissionSet;
  const allowedRoles = new Set([
    ADMIN,
    TRANSPERFECT_ADMIN,
    TRANSPERFECT_USER,
    GLGO_ADMIN,
  ]);

  const canManageUsers = hasPageViewPermissions(permissions, allowedRoles);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [opened, { open, close }] = useDisclosure(false);
  const [modalAction, setModalAction] = useState<ModalAction>('Add');
  const [currentUser, setCurrentUser] = useState(defaultProjectUserPermission);

  const noTPUserPermissionMap = Object.fromEntries(
    Object.entries(projectUserPermissionMap).filter(
      ([, { rolePermissionSet }]: [
        string,
        { rolePermissionSet: Set<string> },
      ]) =>
        !rolePermissionSet.has(TRANSPERFECT_USER) &&
        !rolePermissionSet.has(TRANSPERFECT_ADMIN)
    )
  );

  const filterData = (data: UserManagementRowData[]) => {
    const query = search.toLowerCase().trim();
    return data.filter((rowData) => {
      const { name = '', email = '', roles = '', languages = '' } = rowData;
      return (
        name.toLowerCase().includes(query) ||
        email.toLowerCase().includes(query) ||
        roles.toLowerCase().includes(query) ||
        languages.toLowerCase().includes(query)
      );
    });
  };

  const columns: MRT_ColumnDef<UserManagementRowData>[] = [
    {
      ...getBasicColumn('name', 'name', true),
    },
    {
      ...getBasicColumn('email', 'email', true),
    },
    {
      ...getBasicColumn('roles', 'roles', true),
    },
    {
      ...getBasicColumn('languages', 'languages', true),
    },
  ];

  const manageUserColumn = getEditDeleteColumn(
    ({ data }) => (
      <TableEditButton
        onClick={() => {
          setModalAction('Edit');
          setCurrentUser(projectUserPermissionMap[data.user_key]);
          open();
        }}
      />
    ),
    ({ data }) => (
      <TableDeleteButton
        onClick={() => handleRemoveUser(data as UserManagementRowData)}
        disabled={userKey === data.user_key}
      />
    )
  );

  canManageUsers && columns.push(manageUserColumn);

  const rowData = useMemo(
    () =>
      filterData(
        Object.values(noTPUserPermissionMap).map(
          (user) =>
            ({
              ...user,
              name: `${user.first_name} ${user.last_name}`,
              roles: Array.from(user.rolePermissionSet).join(', '),
              languages: Array.from(user.languagePermissionSet)
                .map(
                  (translationKey) =>
                    projectTranslationMap[translationKey].target_lang_name
                )
                .join(', '),
            }) as UserManagementRowData
        )
      ),
    [search, projectUserPermissionMap]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  const handleRemoveUser = (row: UserManagementRowData) => {
    const { user_key } = row;

    setLoading(true);
    Xapis.User.delete(user_key).then((response) => {
      if (!isSuccessStatus(response.status)) failure(response);
      setLoading(false);
    });
  };

  return (
    <Flex direction="column" rowGap="1rem">
      <Header title="Users" />
      <Flex w="100%" justify="space-between" align="center">
        <Input
          maw={500}
          placeholder="Search by any field"
          classNames={{
            input: classes.searchBarInput,
            section: classes.searchBarRightSection,
          }}
          rightSection={<MdSearch color="#00328D" size={20} />}
          value={search}
          onChange={handleSearchChange}
          style={{ flexGrow: 1 }}
        />
        {canManageUsers && (
          <Button
            variant="transparent"
            rightSection={<IoMdAdd size={20} />}
            onClick={() => {
              open();
              setModalAction('Add');
              setCurrentUser(defaultProjectUserPermission); // Clear current user data
            }}
          >
            Add User
          </Button>
        )}
      </Flex>
      <UserManagementModal
        opened={opened}
        close={close}
        modalAction={modalAction}
        user={currentUser}
      />
      <Card p={0}>
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <CustomTable
          data={rowData}
          columns={columns}
          bottomToolbarString={rowData.length === 1 ? 'User' : 'Users'}
        />
      </Card>
    </Flex>
  );
};

export default UserManagementPage;
