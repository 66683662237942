import React from 'react';
import { Text } from '@mantine/core';
import { DowngradeParams, downgrade } from '../planActions';
import { ActionModalType } from '../../select-plans/SelectPlan';
import CustomModal from '../../../../CustomModal';

type Props = {
  newThresholdValue: number;
  modalType: 'downgrade' | 'upgrade' | null;
  setActionModalType: React.Dispatch<React.SetStateAction<ActionModalType>>;
  params: DowngradeParams;
};

const DowngradeConfirmModal = ({
  newThresholdValue,
  setActionModalType,
  modalType,
  params,
}: Props) => {
  return (
    <CustomModal
      size="lg"
      opened={modalType === 'downgrade'}
      onClose={() => setActionModalType(null)}
      title="Are you sure you want to downgrade to this plan?"
      footerActions={[
        { label: 'Keep Account', action: () => setActionModalType(null) },
        {
          label: 'Confirm',
          action: async () => {
            setActionModalType(null);
            await downgrade({ ...params });
          },
        },
      ]}
    >
      <Text fz="1.15rem" ta="center">
        You are about to downgrade your account to{' '}
        {newThresholdValue.toLocaleString('en-US')} words per month. When you
        do,{' '}
        <em>
          <strong>you will lose some features.</strong>
        </em>
        <ul>
          <li>
            <strong>Words Served:</strong> Your account will be limited to{' '}
            {newThresholdValue.toLocaleString('en-US')} words served per month.
          </li>
        </ul>
        Keep your subscription to retain access to all these great features and
        more! If you do decide to downgrade, you can always upgrade your
        subscription at any time. You will not be charged at this time. Your
        current subscription will continue until your next subscription renewal
        date. At that time, you will be charged the new rate of your
        subscription.
      </Text>
    </CustomModal>
  );
};

export default DowngradeConfirmModal;
