import React from 'react';
import { Divider, Flex, Text, Tooltip } from '@mantine/core';
import { BsExclude, BsIntersect } from 'react-icons/bs';
import { InfoIcon } from '../../../../../../StaticIcons';

type Props = {
  currentScope: string;
};

export const ScopeDivider = ({ currentScope }: Props) => (
  <Flex align="center" my="1.5rem" w="100%">
    {currentScope === 'exclude' ? (
      <BsExclude fill="#5D6B89" width={20} height={20} />
    ) : (
      <BsIntersect fill="#167934" width={20} height={20} />
    )}
    <Text c="text.2" fw={700} ml="0.5rem">
      {currentScope === 'exclude' ? 'Exclusions' : 'Inclusions'}
    </Text>
    <Tooltip
      label={`The ${currentScope === 'exclude' ? 'exclusion' : 'inclusion'} rules you have set are listed below.`}
    >
      <div style={{ marginTop: 5, marginLeft: 10 }}>
        <InfoIcon fill="#2C49AC" size={15} />
      </div>
    </Tooltip>
    <Divider color="divider.0" size="sm" ml="1rem" w="100%" />
  </Flex>
);

export default ScopeDivider;
