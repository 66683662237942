import React from 'react';
import {
  BackgroundImage,
  Button,
  Card,
  Divider,
  Flex,
  Text,
} from '@mantine/core';
import StepsTimeline from './StepsTimeline';
import GetStartedBackground from './images/get-started-background.svg';
import { useSetupStepsContext } from './ProjectSetupProvider';
import GLA from '../../googleAnalytics';
import fathomEventTracker from '../../fathomAnalytics';

export const GetStartedSideMenuContent = () => {
  const {
    sendTagEvent,
    EVENT_CLICK,
    EVENT_ACTION_CREATE_PROJECT_BUTTON,
    EVENT_LABEL_CREATE_PROJECT_FORM,
  } = GLA;
  const { setActiveStep, setIsNextButtonDisabled } = useSetupStepsContext();
  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <Text fz="2rem" fw={700}>
        Let's get started!
      </Text>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz="1.15rem" fw={500} ta="center">
        We’re going to walk you through the process of setting up your website
        translation project step-by-step. This can take a few minutes.
      </Text>
      <Button
        w={{ base: '100%', xs: 'auto' }}
        onClick={() => {
          fathomEventTracker('Project Initiate - 1');
          sendTagEvent(
            EVENT_CLICK,
            EVENT_ACTION_CREATE_PROJECT_BUTTON,
            EVENT_LABEL_CREATE_PROJECT_FORM
          );
          setIsNextButtonDisabled(true);
          setActiveStep((prevStep) => prevStep + 0.5);
        }}
      >
        <Text c="inherit" fz="inherit" fw="inherit" id="project-initiate-1">
          Next
        </Text>
      </Button>
    </Flex>
  );
};

export const GetStartedBodyContent = () => (
  <BackgroundImage
    src={`"${GetStartedBackground}"`}
    style={{
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    }}
    pl="3rem"
    pr="2rem"
  >
    <Flex w="100%" h="100vh" align="center">
      <StepsTimeline />
    </Flex>
  </BackgroundImage>
);

export const MobileGetStartedStep = () => {
  const { setActiveStep, setIsNextButtonDisabled } = useSetupStepsContext();
  const {
    sendTagEvent,
    EVENT_CLICK,
    EVENT_ACTION_CREATE_PROJECT_BUTTON,
    EVENT_LABEL_CREATE_PROJECT_FORM,
  } = GLA;

  return (
    <Card maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex
        w="100%"
        h="100%"
        rowGap="1.5rem"
        direction="column"
        justify="center"
        align="center"
      >
        <Text fz={{ base: '1.5rem', sm: '2rem' }} fw={700}>
          Let's get started!
        </Text>
        <Divider w="100%" color="divider.0" size={2} />
        <Text fz={{ base: '1rem', sm: '1.15rem' }} fw={500} ta="center">
          We’re going to walk you through the process of setting up your website
          translation project step-by-step. This can take a few minutes.
        </Text>
        <Button
          w={{ base: '100%', xs: 'auto' }}
          onClick={() => {
            fathomEventTracker('Project Initiate - 1');
            sendTagEvent(
              EVENT_CLICK,
              EVENT_ACTION_CREATE_PROJECT_BUTTON,
              EVENT_LABEL_CREATE_PROJECT_FORM
            );
            setIsNextButtonDisabled(true);
            setActiveStep((prevStep) => prevStep + 0.5);
          }}
        >
          <Text c="inherit" fz="inherit" fw="inherit" id="project-initiate-1">
            Next
          </Text>
        </Button>
      </Flex>
    </Card>
  );
};
