import React, { useMemo, useState } from 'react';
import { Box, Button, Card, Divider, Flex, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import ScopeExplanationCard from './ScopeExplanationCard';
import ScopeChangeFormModal from './ScopeChangeFormModal';
import { HiPencil } from 'react-icons/hi';
import { defaultScopeDetails, scopeInfo } from './scopeInfo';
import { getYYTarget, hexToObject, encodeKey } from 'helpers';
import { Link } from 'react-router-dom';
import { useCurrentProjectData } from 'store';

export const CurrentScopeHome = () => {
  const isMobileSize = useMediaQuery('(max-width: 991px)');

  const { project: { translations = [] } = {} } = useCurrentProjectData();

  const yyTarget = getYYTarget(translations) as TranslationKey;

  const { scope_preset = '' } = useMemo(() => {
    return hexToObject(yyTarget?.translation_config || '');
  }, [yyTarget?.translation_config]);

  const defaultScope = ['all', 'include', 'exclude'].includes(scope_preset)
    ? scope_preset
    : 'all';

  const [currentScope, setCurrentScope] = useState(defaultScope);
  const [showFormModal, setShowFormModal] = useState(false);

  const currentScopeDetails =
    scopeInfo.find(
      ({ scopeName }: { scopeName: string }) => scopeName === currentScope
    ) || defaultScopeDetails;
  const { scopeName, scopeDesc, scopeColor, scopeButtonText, ScopeIcon } =
    currentScopeDetails;

  let scopeTitle = scopeName === 'all' ? 'Everything' : scopeName;
  scopeTitle = `${scopeTitle[0].toUpperCase()}${scopeTitle.substring(1)}`;

  const getScopeIconProps = () => {
    const baseProps = { height: '21px', width: '21px' };
    return scopeName === 'all'
      ? baseProps
      : { ...baseProps, fill: scopeColor, size: '21px' };
  };

  if (!currentScope) return <></>;

  return (
    <>
      {showFormModal && (
        <ScopeChangeFormModal
          setShowFormModal={setShowFormModal}
          showFormModal={showFormModal}
          currentScope={currentScope}
          setCurrentScope={setCurrentScope}
        />
      )}
      <Flex direction={isMobileSize ? 'column' : 'row'} rowGap={10} mt={16}>
        <Card
          h="fit-content"
          w={isMobileSize ? '100%' : '47.5%'}
          p={{ base: 20, xs: 30, sm: 40 }}
        >
          <Flex align="center" w="100%" justify="space-between" columnGap={5}>
            <Box>
              <ScopeIcon display="inline-block" {...getScopeIconProps()} />
              <Text display="inline" fw="700" fz="1.75rem" lh={1.2} ml={5}>
                <Text fz="inherit" fw="inherit" c={scopeColor} span>
                  {scopeTitle}
                </Text>
                {scopeTitle === 'Everything'
                  ? ' on your site is in scope'
                  : " is your project's scope"}
              </Text>
            </Box>
            <Box
              p={5}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowFormModal(true)}
            >
              <HiPencil size="1.5rem" color="#0071DB" />
            </Box>
          </Flex>
          <Flex direction="row" columnGap={30} pl={5} mt={20}>
            <Divider my={5} orientation="vertical" size="sm" color="border.1" />
            <Text c="text.2" fw="500" fz="1rem">
              {scopeDesc}
            </Text>
          </Flex>
          <Link
            to={`${encodeKey(yyTarget?.translation_key || '', 't')}/urls`}
            style={{ textDecoration: 'none', margin: '0 auto 0 auto' }}
          >
            <Button mt="1rem">{scopeButtonText}</Button>
          </Link>
        </Card>
        <Flex justify="center" w={isMobileSize ? '100%' : '5%'}>
          <Divider h="100%" orientation="vertical" size="sm" color="border.1" />
        </Flex>
        <ScopeExplanationCard isMobileSize={isMobileSize || false} />
      </Flex>
    </>
  );
};

export default CurrentScopeHome;
