import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Flex, Loader, Tabs, Text } from '@mantine/core';
import { useCurrentProjectData } from 'store';
import { hexToAscii } from 'helpers';
import {
  positionOptions,
  LanguageSelectorPosition,
  LanguageSelectorTheme,
  isPresetCss,
  getCSSPreferences,
  getCustomCSS,
  getLanguageSelectorPositions,
  SelectorDefault,
} from '../../gosetup/LanguageSelectorAppearances';
import classes from './SelectorDefaults.module.css';
import { LangSelectorPreview } from '../custom-css-editor/LangSelectorPreview';
import { DARK_MODE_THEME } from './CustomThemeButton';
import SelectorVisibilityOptions from './components/SelectorVisibilityOptions';
import BasicCustomization from './components/BasicCustomization';
import CssEditor from './components/CssEditor';
import ConfirmOverrideCSSModal from './components/ConfirmOverrideModal';
import updateAllAppearances from './updateAllAppearances';
import { getNoteText, getCSS } from './utils';
import PreferenceButton from './components/PreferenceButton';

const SelectorCustomization = () => {
  const { yyTranslation: yyTarget } = useCurrentProjectData();

  const [showExplanation, setShowExplanation] = useState(false);

  const { custom_css: hexedCss = '' } = yyTarget || {};

  const customCss = hexToAscii(hexedCss);

  const isCurrentAPreset = isPresetCss(customCss);
  const currentPreset = useMemo(
    () => getCSSPreferences(customCss),
    [customCss]
  );

  //keeps track of any changes made not in the CSS editor
  const [selectedPreset, setSelectedPreset] = useState<SelectorDefault>({
    ...currentPreset,
    theme: currentPreset.theme ?? DARK_MODE_THEME,
  });

  const [loading, setLoading] = useState(false);
  const [showOverrideCSSModal, setShowOverrideCSSModal] = useState(false);

  const [cssEditorCss, setCssEditorCss] = useState(customCss);

  const [whichSelectorShown, setWhichSelectorShown] = useState('None');
  const [whichTabShown, setWhichTabShown] = useState(
    isCurrentAPreset ? 'Basic' : 'css'
  );
  const [showHiddenOverCurrentSelector, setShowHiddenOverCurrentSelector] =
    useState(true);

  const isPresetSelected = Boolean(
    selectedPreset.position && selectedPreset.theme
  );
  const isUnsaved = Object.entries(currentPreset).some(
    ([key, value]) =>
      selectedPreset[key as keyof SelectorDefault] !== value &&
      !(key === 'theme' && !value)
  );
  //used to update the options when the css changed (due to a change in the css editor)
  useEffect(() => {
    const isCurrentAPreset = isPresetCss(customCss);
    if (!currentPreset || !isCurrentAPreset) {
      setSelectedPreset({
        position: undefined,
        theme: DARK_MODE_THEME,
        width: undefined,
      });
    } else {
      setSelectedPreset(currentPreset);
    }
  }, [customCss]);

  const disableSave = !(isUnsaved && isPresetSelected);

  const {
    theme,
    position = 'top_left' as LanguageSelectorPosition,
    width,
  } = selectedPreset;
  const { topBottom, leftRight } = getLanguageSelectorPositions(position);
  const updatedCustomCSS = getCustomCSS(topBottom, leftRight, theme, width);

  const handleThemeChange = (updatedTheme: LanguageSelectorTheme) => {
    const newPreset = { ...selectedPreset, theme: updatedTheme };

    const isUnsaved = Object.entries(currentPreset).some(
      ([key, value]) =>
        newPreset[key as keyof SelectorDefault] !== value &&
        !(key === 'theme' && !value)
    );
    setSelectedPreset((prev) => ({ ...prev, theme: updatedTheme }));
    setWhichSelectorShown(
      !(isUnsaved && isPresetSelected)
        ? showHiddenOverCurrentSelector
          ? 'None'
          : 'Current'
        : 'New'
    );
  };
  const handlePositionChange = (updatedPosition: LanguageSelectorPosition) => {
    const newPreset = { ...selectedPreset, position: updatedPosition };

    const isUnsaved = Object.entries(currentPreset).some(
      ([key, value]) =>
        newPreset[key as keyof SelectorDefault] !== value &&
        !(key === 'theme' && !value)
    );

    setSelectedPreset((prev) => ({ ...prev, position: updatedPosition }));
    setWhichSelectorShown(
      !(isUnsaved && updatedPosition)
        ? showHiddenOverCurrentSelector
          ? 'None'
          : 'Current'
        : 'New'
    );
  };
  const handleWidthChange = (updatedWidth: number) => {
    const newPreset = { ...selectedPreset, width: updatedWidth };

    const isUnsaved = Object.entries(currentPreset).some(
      ([key, value]) =>
        newPreset[key as keyof SelectorDefault] !== value &&
        !(key === 'theme' && !value)
    );
    setSelectedPreset((prev) => ({ ...prev, width: updatedWidth }));
    setWhichSelectorShown(
      !(isUnsaved && isPresetSelected)
        ? showHiddenOverCurrentSelector
          ? 'None'
          : 'Current'
        : 'New'
    );
  };

  const selectorPositions = positionOptions.map(
    ({ position, src, alt = '' }) => {
      const isPositionActive = position === selectedPreset.position;
      return (
        <PreferenceButton
          key={position}
          disabled={loading}
          isActive={isPositionActive}
          onClick={() => handlePositionChange(position)}
          src={src}
          alt={alt}
        />
      );
    }
  );

  const cssEditorProps = {
    showExplanation,
    setShowExplanation,
    yyTarget,
    cssEditorCss,
    setCssEditorCss,
    customCss,
    setWhichSelectorShown,
    showHiddenOverCurrentSelector,
  };

  const basicCustomizationSaveButtons = (
    <Flex direction="column" mt={10}>
      <Flex align="center" justify="space-between">
        <Text className={classes.note} mb={15}>
          {getNoteText(isCurrentAPreset, isPresetSelected)}
        </Text>
        <Flex justify="flex-end" gap={20} align="center" mb={10}>
          <Flex
            columnGap={20}
            rowGap={10}
            wrap="wrap"
            justify="center"
            display={!isUnsaved ? 'none' : 'inherit'}
          >
            <Button
              variant="outline"
              disabled={!isUnsaved}
              onClick={() => {
                setSelectedPreset(currentPreset);
                setWhichSelectorShown(
                  showHiddenOverCurrentSelector ? 'None' : 'Current'
                );
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={disableSave}
              onClick={() => {
                isCurrentAPreset
                  ? updateAllAppearances(
                      yyTarget,
                      updatedCustomCSS,
                      setShowOverrideCSSModal,
                      setLoading
                    )
                  : setShowOverrideCSSModal(true);
              }}
            >
              {loading ? <Loader color="cta1.6" size="sm" /> : 'Save'}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Divider
        orientation="horizontal"
        style={{
          borderWidth: 2,
          borderColor: 'var(--mantine-color-divider-0)',
        }}
      />
    </Flex>
  );

  return (
    <>
      <Flex justify="flex-end" gap={10} align="center">
        Preview:
        <SelectorVisibilityOptions
          whichSelectorShown={whichSelectorShown}
          setWhichSelectorShown={setWhichSelectorShown}
          whichTabShown={whichTabShown}
          setShowHiddenOverCurrentSelector={setShowHiddenOverCurrentSelector}
          isUnsaved={isUnsaved}
          customCss={customCss}
          cssEditorCss={cssEditorCss}
        />
      </Flex>
      <Tabs
        value={whichTabShown}
        onChange={(tab) => {
          setWhichTabShown(tab ?? 'Basic');
          setWhichSelectorShown(
            (tab === 'Basic' ? disableSave : cssEditorCss === customCss)
              ? showHiddenOverCurrentSelector
                ? 'None'
                : 'Current'
              : 'New'
          );
        }}
      >
        <Tabs.List mb={20}>
          <Tabs.Tab value="Basic">Basic Customization</Tabs.Tab>
          <Tabs.Tab value="css">CSS Editor</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="Basic">
          {!isCurrentAPreset && (
            <Flex justify="center">
              <Text c="red" mb={20}>
                You are currently using a custom css, using the basic
                customization tool will override your existing settings
              </Text>
            </Flex>
          )}
          <BasicCustomization
            selectedPreset={selectedPreset}
            currentPreset={currentPreset}
            handleThemeChange={handleThemeChange}
            handleWidthChange={handleWidthChange}
            selectorPositions={selectorPositions}
          />
          {basicCustomizationSaveButtons}
        </Tabs.Panel>
        <Tabs.Panel value="css">
          <CssEditor {...cssEditorProps} />
        </Tabs.Panel>
      </Tabs>
      <LangSelectorPreview
        css={getCSS(
          customCss,
          updatedCustomCSS,
          cssEditorCss,
          whichSelectorShown,
          whichTabShown
        )}
        showButton={false}
      />
      <ConfirmOverrideCSSModal
        showOverrideCSSModal={showOverrideCSSModal}
        setShowOverrideCSSModal={setShowOverrideCSSModal}
        loading={loading}
        setLoading={setLoading}
        updatedCustomCSS={updatedCustomCSS}
        yyTarget={yyTarget}
      />
    </>
  );
};

export default SelectorCustomization;
