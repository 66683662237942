import React from 'react';
import { PlanOption } from '../plan-information/PlanInformation';
import {
  Badge,
  Box,
  Card,
  Divider,
  Flex,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { formatDollarAmount } from 'helpers';

type Props = {
  plan: PlanOption;
  handleSubSelect(item: PlanOption): void;
  isSelectedPlan: boolean;
  isCurrentPlan: boolean;
  isRecommendedPlan: boolean;
};

const MobilePlan = ({
  plan,
  handleSubSelect,
  isSelectedPlan,
  isCurrentPlan,
  isRecommendedPlan,
}: Props) => {
  const colors = useMantineTheme().colors;
  const isMonthly = plan.subscription_type === 'MONTHLY';
  const getCardBorderStyle = () => {
    if (isCurrentPlan) {
      return '2.5px solid text.2';
    }
    if (isSelectedPlan) {
      return '1px solid text.8';
    }
    return '1px solid ' + colors.border[1];
  };

  return (
    <Card
      bd={getCardBorderStyle()}
      key={plan.sku_name}
      h={110}
      mx={5}
      mt={10}
      onClick={() => (isCurrentPlan ? null : handleSubSelect(plan))}
      shadow="none"
      p={0}
      style={{
        borderRadius: 10,
        borderWidth: isCurrentPlan ? '2.5px' : '1px',
        cursor: isCurrentPlan ? 'not-allowed' : 'pointer',
        overflow: 'visible',
        boxShadow: isSelectedPlan
          ? `0px 0px 5px 5px ${colors.text[8]}`
          : 'none',
      }}
      ta="center"
    >
      {isRecommendedPlan && (
        <Badge
          bg="#F8BE31"
          c="text.2"
          pos="absolute"
          top={-11}
          left={{ base: '30%', xs: '35%' }}
          mx="auto"
          style={{ zIndex: 1 }}
        >
          Recommended
        </Badge>
      )}
      <Box
        style={{ overflow: 'hidden' }}
        h="100%"
        w="100%"
        p="lg"
        pos="relative"
      >
        {isCurrentPlan && (
          <Box
            bg={colors.text[2]}
            pos="absolute"
            top={{ base: '8px', xs: '12px' }}
            right={{ base: -20, xs: -30 }}
            w="30%"
            style={{ transform: 'rotate(45deg)' }}
          >
            <Text c="white" fz={{ base: 10, xs: 'xs' }}>
              Current
            </Text>
          </Box>
        )}
        <Flex align="center" h="100%" justify="space-between">
          <Text
            fw={700}
            fz={{ base: '0.9rem', xs: '1rem' }}
            c="text.2"
            ta="left"
            flex={1}
          >
            {plan.sku_name}
          </Text>
          <Flex justify="center" h="100%" flex={1}>
            <Divider orientation="vertical" size="sm" />
          </Flex>
          <Flex direction="column" flex={1}>
            <Text fw={700} fz="1.75rem" ta="left">
              {formatDollarAmount(String(plan.price), {
                maximumFractionDigits: 0,
              })}
            </Text>
            <Text fw={600} ta="right" size="sm">
              <Text display={{ base: 'none', xs: 'inline' }} span>
                /
              </Text>{' '}
              {isMonthly ? 'Monthly' : 'Annually'}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Card>
  );
};

export default MobilePlan;
