import { Card, Title } from '@mantine/core';
import SelectorCustomization from './SelectorCustomization';
import { SelectorContentCustomization } from './SelectorContentCustomization';

export const SelectorCard = () => {
  return (
    <Card w="min(991px, 100%)" px={20} mt={16}>
      <Title mx="auto" order={4} p="sm" tt="uppercase" w="fit-content">
        Customize Your Selector
      </Title>
      <SelectorCustomization />
      <SelectorContentCustomization />
    </Card>
  );
};

export default SelectorCard;
