import { useMantineTheme } from '@mantine/core';
import { MdArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Header } from '../../../Generic';

type Props = {
  text: string;
};

const BackButton = ({ text }: Props) => {
  const colors = useMantineTheme().colors;
  return (
    <Link
      to=".."
      style={{ display: 'block', textDecoration: 'none', width: 'fit-content' }}
    >
      <Header
        title={text}
        flexItems={<MdArrowBack color={colors.text[8]} size={24} />}
        flexContainerProps={{
          w: 'fit-content',
          wrap: 'nowrap',
          direction: 'row-reverse',
        }}
      />
    </Link>
  );
};

export default BackButton;
