import { Box, ScrollArea, Stack, Text, UnstyledButton } from '@mantine/core';
import CustomModal from '../../../CustomModal';
import { useState } from 'react';
import DOMPurify from 'dompurify';
import { SetURLSearchParams, useRouteLoaderData } from 'react-router-dom';
import { ProjectQueryLoaderData } from 'store';
import { NOOP } from 'helpers';

type Props = {
  opened: boolean;
  setOpened: (value: ((prevState: boolean) => boolean) | boolean) => void;
  params: URLSearchParams;
  setParams: SetURLSearchParams;
  showLatest: boolean;
};

export default function AboutModal({
  opened,
  setOpened,
  params,
  setParams,
  showLatest,
}: Props) {
  const { releaseNotes = [] } = useRouteLoaderData(
    'project'
  ) as ProjectQueryLoaderData;
  const [showBuildsList, setShowBuildsList] = useState(showLatest);

  const { VITE_APP_VERSION = 'NOT_FOUND', VITE_BUILD_TIMESTAMP = 'NOT_FOUND' } =
    import.meta.env || {};

  const BuildVersionText = () => {
    return (
      <>
        <Text span>Build: </Text>
        {releaseNotes.length > 0 ? (
          <UnstyledButton
            c="text.8"
            onClick={() =>
              releaseNotes.length > 0 ? setShowBuildsList(true) : NOOP
            }
            td="underline"
          >
            {VITE_APP_VERSION}{' '}
          </UnstyledButton>
        ) : (
          <Text span>{VITE_APP_VERSION}</Text>
        )}
        <Text span> {VITE_BUILD_TIMESTAMP}</Text>
      </>
    );
  };

  const handleClose = () => {
    setTimeout(() => {
      setShowBuildsList(false);
    }, 500);
    setOpened(false);
    params.delete('viewNotes');
    setParams(params);
  };

  return (
    <CustomModal
      onClose={handleClose}
      opened={opened}
      title={showBuildsList || showLatest ? 'Release Notes' : 'About'}
    >
      {showBuildsList || showLatest ? (
        <ScrollArea offsetScrollbars h={350}>
          <Box m="md">
            <Stack gap={5}>
              {releaseNotes.length > 0 ? (
                releaseNotes.map(({ content, title }, i) => {
                  const shouldHighlightLatest = i === 0 && showLatest;

                  return (
                    <Stack
                      gap={0}
                      key={title.rendered}
                      bg={
                        shouldHighlightLatest ? 'background.7' : 'transparent'
                      }
                      bd={
                        shouldHighlightLatest
                          ? '3px solid background.5'
                          : 'none'
                      }
                      style={{ borderRadius: 10 }}
                      p={10}
                    >
                      <Text fw={shouldHighlightLatest ? 600 : 400}>
                        {title.rendered}
                        {i === 0 ? ' (current) ' : ''}
                      </Text>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(content.rendered),
                        }}
                      />
                    </Stack>
                  );
                })
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </ScrollArea>
      ) : (
        <BuildVersionText />
      )}
    </CustomModal>
  );
}
