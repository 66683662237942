import { Flex, Anchor, Text } from '@mantine/core';
import { LanguageCSSEditor } from '../../custom-css-editor/LanguageCSSEditor';
import { SetStateAction } from 'react';
import { GLWEB_SUPPORT_URL } from 'helpers';

type Props = {
  showExplanation: boolean;
  setShowExplanation: React.Dispatch<React.SetStateAction<boolean>>;
  yyTarget: TranslationKey | undefined;
  cssEditorCss: string;
  setCssEditorCss: React.Dispatch<SetStateAction<string>>;
  customCss: string;
  setWhichSelectorShown: React.Dispatch<SetStateAction<string>>;
  showHiddenOverCurrentSelector: boolean;
};

const CssEditor = ({
  showExplanation,
  setShowExplanation,
  yyTarget,
  cssEditorCss,
  setCssEditorCss,
  customCss,
  setWhichSelectorShown,
  showHiddenOverCurrentSelector,
}: Props) => (
  <>
    <Flex gap={20}>
      <Text>CSS Editor</Text>
      <Flex direction="column">
        <Text
          c="text.8"
          fw="bold"
          fz="0.95rem"
          onClick={(e) => {
            e.stopPropagation();
            setShowExplanation((s) => !s);
          }}
        >
          {showExplanation ? 'Hide details' : "What's this?"}
        </Text>
        {showExplanation ? (
          <Text fw="500" fz="1rem" maw={700} mt={10} mb={10} pl={0}>
            You can customize the styling of the language selector on your
            website by using the CSS Editor. It is pre-populated with your
            current settings.&nbsp;
            <Anchor
              c="text.8"
              href={`${GLWEB_SUPPORT_URL}/article/139-how-do-i-customize-the-look-of-the-language-selector`}
              target="_blank"
            >
              Learn more about customizing your selector.
            </Anchor>
          </Text>
        ) : (
          <></>
        )}
      </Flex>
    </Flex>
    <>
      <Text mb={20} mt={10}>
        <Text span fw="bold">
          NOTE:
        </Text>{' '}
        This editor will only apply to the widget and override any selections
        made above.
      </Text>
      <LanguageCSSEditor
        target={yyTarget}
        cssEditorCss={cssEditorCss}
        setCssEditorCss={(css) => {
          setCssEditorCss(css);
          css === customCss
            ? setWhichSelectorShown(
                showHiddenOverCurrentSelector ? 'None' : 'Current'
              )
            : setWhichSelectorShown('New');
        }}
        showPreviewButton={false}
      />
    </>
  </>
);

export default CssEditor;
