import React from 'react';
import { ActionIcon, Menu } from '@mantine/core';
import { FaTrashCan } from 'react-icons/fa6';
import { useGLGOParams } from 'hooks';
import { SpiderTreeNode } from 'store';
import { handleUpdateRules } from 'mui';
import classes from './ConfirmDeleteMenu.module.css';

type Props = {
  ruleToRemove: string;
  rulesList: string[];
  yyTarget: TranslationKey;
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>;
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};

const ConfirmDeleteMenu = ({
  ruleToRemove,
  rulesList,
  yyTarget,
  setYYTarget,
}: Props) => {
  const { projectKey = '' } = useGLGOParams();

  return (
    <Menu
      position="bottom-end"
      width={150}
      withArrow
      classNames={{
        arrow: classes.arrow,
        divider: classes.divider,
        item: classes.item,
      }}
    >
      <Menu.Target>
        <ActionIcon bg="transparent" color="#006CD1">
          <FaTrashCan fill="#006CD1" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown style={{ border: '1.5px solid #5C73BC', padding: 0 }}>
        <Menu.Item
          onClick={() =>
            handleUpdateRules(
              projectKey,
              rulesList,
              ruleToRemove,
              yyTarget,
              setYYTarget
            )
          }
          color="text.2"
          fw={500}
          p="0.5rem 0.75rem"
        >
          Confirm Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ConfirmDeleteMenu;
