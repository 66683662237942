import React, { useState } from 'react';
import {
  Anchor,
  Box,
  Button,
  Card,
  CopyButton,
  Divider,
  Flex,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { useSetupStepsContext } from './ProjectSetupProvider';
import BrowserWindow from './BrowserWindow';
import { ProjectStore, useProjectsStore, xapisEnv } from 'store';
import { MobileActionHeader } from './MobileActionHeader';
import { encodeProjectKey, GLWEB_SUPPORT_URL } from 'helpers';
import SkipInjectionModal from './SkipInjectionModal';
import classes from './CopySnippetStep.module.css';
import GLA from '../../googleAnalytics';
import fathomEventTracker from '../../fathomAnalytics';

export const CopySnippetSideMenuContent = () => {
  const {
    sendTagEvent,
    EVENT_CLICK,
    EVENT_ACTION_CREATE_PROJECT_BUTTON,
    EVENT_ACTION_BACK,
    EVENT_ACTION_SKIP,
    EVENT_LABEL_COPY_SNIPPET_FORM,
  } = GLA;
  const { setActiveStep, isNextButtonDisabled, setIsNextButtonDisabled } =
    useSetupStepsContext();

  const { activeProject } = useProjectsStore((state: ProjectStore) => state);
  const { projectKey = '' } = activeProject || {};
  const encodedProjectKey = encodeProjectKey(projectKey);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SkipInjectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        encodedProjectKey={encodedProjectKey}
      />
      <Flex
        w="100%"
        maw={450}
        rowGap="1.5rem"
        direction="column"
        justify="center"
        align="center"
      >
        <Text fz="2rem" fw={700}>
          Add Code Snippet
        </Text>
        <Divider w="100%" color="divider.0" size={2} />
        <Text fz="1.15rem" fw={500} ta="center">
          Copy the snippet and paste it within the &lt;head&gt; of your website.
        </Text>
        <Flex columnGap="1rem">
          <Button
            w={{ base: '100%', xs: 'auto' }}
            variant="outline"
            onClick={() => {
              sendTagEvent(
                EVENT_CLICK,
                EVENT_ACTION_BACK,
                EVENT_LABEL_COPY_SNIPPET_FORM
              );
              setActiveStep((prevStep) => prevStep - 1);
            }}
          >
            Back
          </Button>
          <Button
            w={{ base: '100%', xs: 'auto' }}
            disabled={isNextButtonDisabled}
            onClick={() => {
              fathomEventTracker('Add Snippet - 5');
              sendTagEvent(
                EVENT_CLICK,
                EVENT_ACTION_CREATE_PROJECT_BUTTON,
                EVENT_LABEL_COPY_SNIPPET_FORM
              );
              setIsNextButtonDisabled(true);
              setActiveStep((prevStep) => prevStep + 1);
            }}
          >
            <Text c="inherit" fz="inherit" fw="inherit" id="add-snippet-5">
              Next
            </Text>
          </Button>
        </Flex>
        <UnstyledButton
          id="add-snippet-skip-5"
          onClick={() => {
            fathomEventTracker('Add Snippet Skip - 5');
            sendTagEvent(
              EVENT_CLICK,
              EVENT_ACTION_SKIP,
              EVENT_LABEL_COPY_SNIPPET_FORM
            );
            setIsModalOpen(true);
          }}
          c="text.8"
          fw={600}
          fz="1.1rem"
          td="underline"
        >
          Skip for now.
        </UnstyledButton>
      </Flex>
    </>
  );
};

export const CopySnippetBodyContent = () => {
  const {
    sendTagEvent,
    EVENT_CLICK,
    EVENT_ACTION_COPY_SNIPPET,
    EVENT_LABEL_COPY_SNIPPET_FORM,
  } = GLA;
  const { setIsNextButtonDisabled } = useSetupStepsContext();
  const { activeProject } = useProjectsStore((state: ProjectStore) => state);
  const { projectKey: activeProjectKey = '' } = activeProject;

  // The "P" in front will tell multiple XAPIS APIs to treat this as a project_key
  const moxieProjectKey = `P${activeProjectKey}`;

  const { moxieInjectionUrl = '' } = xapisEnv.getHost;
  const injectionSnippet = `<script referrerpolicy="no-referrer-when-downgrade" type="text/javascript" src="${moxieInjectionUrl}" data-oljs="${moxieProjectKey}"></script>`;

  const [isSnippetCopied, setIsSnippetCopied] = useState(false);

  return (
    <Box px="2rem">
      <BrowserWindow>
        <Flex w="100%" direction="column" rowGap="2rem">
          <Flex
            py="1rem"
            px={{ base: '1rem', md: '2rem' }}
            align="center"
            direction={{ base: 'column', xs: 'row' }}
            justify="space-between"
            rowGap={{ base: '1rem', xs: 0 }}
            className={classes.snippetCard}
          >
            <Box w="80%" onClick={() => setIsNextButtonDisabled(false)}>
              <Text fw={600}>{injectionSnippet}</Text>
            </Box>
            <Tooltip label={isSnippetCopied ? 'Copied!' : 'Copy'}>
              <Box style={{ cursor: 'pointer' }}>
                <CopyButton value={injectionSnippet}>
                  {({ copy }) => (
                    <Button
                      onClick={() => {
                        copy();
                        sendTagEvent(
                          EVENT_CLICK,
                          EVENT_ACTION_COPY_SNIPPET,
                          EVENT_LABEL_COPY_SNIPPET_FORM
                        );
                        setIsSnippetCopied(true);
                        setIsNextButtonDisabled(false);
                      }}
                    >
                      {isSnippetCopied ? 'Copied!' : 'Copy'}
                    </Button>
                  )}
                </CopyButton>
              </Box>
            </Tooltip>
          </Flex>
          <Text fz="1.15rem">
            If you use a CMS, find the option to add a JavaScript snippet in the
            <Text span c="text2.3" fw={600}>
              &nbsp;&lt;head&gt;&nbsp;
            </Text>
            section of your HTML pages (usually in settings). Make sure to click
            “load on all pages” if applicable. Otherwise, paste the snippet on
            all your website HTML pages as close to the opening
            <Text span c="text2.3" fw={600}>
              &nbsp;&lt;head&gt;&nbsp;
            </Text>
            tag as possible, but below any dataLayer declarations.&nbsp;
            <Anchor
              href={`${GLWEB_SUPPORT_URL}/category/5-glgo-integration-guides`}
              target="_blank"
              fw={700}
              td="underline"
            >
              Learn More
            </Anchor>
          </Text>
        </Flex>
      </BrowserWindow>
    </Box>
  );
};

export const MobileCopySnippetStep = () => {
  const {
    sendTagEvent,
    EVENT_CLICK,
    EVENT_ACTION_COPY_SNIPPET,
    EVENT_LABEL_COPY_SNIPPET_FORM,
    EVENT_ACTION_SKIP,
  } = GLA;
  const { setActiveStep, isNextButtonDisabled, setIsNextButtonDisabled } =
    useSetupStepsContext();
  const { activeProject } = useProjectsStore((state: ProjectStore) => state);
  const { projectKey: activeProjectKey = '' } = activeProject;

  // The "P" in front will tell multiple XAPIS APIs to treat this as a project_key
  const moxieProjectKey = `P${activeProjectKey}`;

  const { moxieInjectionUrl = '' } = xapisEnv.getHost;
  const injectionSnippet = `<script referrerpolicy="no-referrer-when-downgrade" type="text/javascript" src="${moxieInjectionUrl}" data-oljs="${moxieProjectKey}"></script>`;
  const encodedProjectKey = encodeProjectKey(activeProjectKey);

  const [isSnippetCopied, setIsSnippetCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SkipInjectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        encodedProjectKey={encodedProjectKey}
      />
      <Card
        w="100%"
        maw={{ base: '100%', xs: 650, lg: 450 }}
        px="1rem"
        py="2rem"
      >
        <Flex
          w="100%"
          h="100%"
          direction="column"
          align="center"
          rowGap="1.5rem"
        >
          <MobileActionHeader title="Add Code Snippet" />
          <Divider w="100%" color="divider.0" size={2} />
          <Text fz={{ base: '1rem', sm: '1.15rem' }} fw={500}>
            Copy the snippet and paste it within the &lt;head&gt; of your
            website.
          </Text>
          <Flex
            py="1rem"
            px={{ base: '1rem', md: '2rem' }}
            align="center"
            direction={{ base: 'column', xs: 'row' }}
            justify="space-between"
            rowGap={{ base: '1rem', xs: 0 }}
            columnGap="1rem"
            style={(theme) => ({
              border: `1px solid ${theme.colors.border[0]}`,
              borderRadius: 15,
              boxShadow: '0px 3px 10px rgba(5, 53, 140, 0.4)',
            })}
          >
            <Box w="100%">
              <Text fw={600}>{injectionSnippet}</Text>
            </Box>
            <Tooltip label={isSnippetCopied ? 'Copied!' : 'Copy'}>
              <Box style={{ cursor: 'pointer' }}>
                <CopyButton value={injectionSnippet}>
                  {({ copy }) => (
                    <Button
                      onClick={() => {
                        copy();
                        sendTagEvent(
                          EVENT_CLICK,
                          EVENT_ACTION_COPY_SNIPPET,
                          EVENT_LABEL_COPY_SNIPPET_FORM
                        );
                        setIsSnippetCopied(true);
                        setIsNextButtonDisabled(false);
                      }}
                    >
                      {isSnippetCopied ? 'Copied!' : 'Copy'}
                    </Button>
                  )}
                </CopyButton>
              </Box>
            </Tooltip>
          </Flex>
          <Text fz={{ base: '1rem', sm: '1.15rem' }} fw={500}>
            If you use a CMS, find the option to add a JavaScript snippet in the
            <Text span c="text2.3">
              &nbsp;&lt;head&gt;&nbsp;
            </Text>
            section of your HTML pages (usually in settings). Make sure to click
            “load on all pages” if applicable. Otherwise, paste the snippet on
            all your website HTML pages as close to the opening
            <Text span c="text2.3">
              &nbsp;&lt;head&gt;&nbsp;
            </Text>
            tag as possible, but below any dataLayer declarations.&nbsp;
            <Anchor
              href={`${GLWEB_SUPPORT_URL}/category/5-glgo-integration-guides`}
              target="_blank"
              fw={700}
              td="underline"
            >
              Learn More
            </Anchor>
          </Text>
          <Divider w="100%" color="divider.0" size={2} />
          <Button
            w={{ base: '100%', xs: 'auto' }}
            disabled={isNextButtonDisabled}
            onClick={() => {
              fathomEventTracker('Add Snippet - 5');
              setIsNextButtonDisabled(true);
              setActiveStep((prevStep) => prevStep + 1);
            }}
          >
            <Text c="inherit" fz="inherit" fw="inherit" id="add-snippet-5">
              Next
            </Text>
          </Button>
          <UnstyledButton
            id="add-snippet-5"
            onClick={() => {
              fathomEventTracker('Add Snippet Skip - 5');
              sendTagEvent(
                EVENT_CLICK,
                EVENT_ACTION_SKIP,
                EVENT_LABEL_COPY_SNIPPET_FORM
              );
              setIsModalOpen(true);
            }}
            c="text.8"
            fw={600}
            fz="1.1rem"
            td="underline"
          >
            Skip for now.
          </UnstyledButton>
          <UnstyledButton
            onClick={() =>
              window.open(
                `${GLWEB_SUPPORT_URL}/category/5-glgo-integration-guides`,
                '_blank'
              )
            }
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Text c="text.8" fw={500} mr="0.75rem" span td="underline">
              Need Help?
            </Text>
          </UnstyledButton>
        </Flex>
      </Card>
    </>
  );
};
