import React from 'react';
import { Flex, Title, Card, Button, Text } from '@mantine/core';
import { ConfirmEnvelope } from 'images';
import { Link, Navigate, useLoaderData } from 'react-router-dom';
import { getPrettyDate } from 'helpers';
import { useGLGOParams } from 'hooks';
import { CancellationConfirmationLoaderData } from 'store';

export const CancellationConfirmationPage = () => {
  const { projectId } = useGLGOParams();
  const { next_renewal_date, is_canceled } =
    useLoaderData() as CancellationConfirmationLoaderData;

  if (is_canceled && next_renewal_date.length > 0) {
    const prettyDate = getPrettyDate(next_renewal_date);

    return (
      <Flex
        mih="calc(100vh - 48px)"
        pt="2rem"
        pb="4rem"
        justify="center"
        direction="column"
        bg="blue.0"
      >
        <Card
          maw={{ base: '95%', sm: '80%', md: '60%' }}
          mx="auto"
          p={{ base: '2rem 1rem', xs: '2rem', sm: '3rem' }}
          radius="md"
          shadow="sm"
          withBorder
        >
          <Flex direction="column" align="center" gap={10}>
            <Flex direction="column" gap={5} ta="center" mb={25}>
              <Title fz="1.9rem" order={1} tt="uppercase">
                Your account has been cancelled
              </Title>
              <Title fz="1.3rem" order={4}>
                Expiration Date: {prettyDate}
              </Title>
            </Flex>
            <Flex
              direction="column"
              align="center"
              gap={25}
              maw={470}
              mx="auto"
            >
              <ConfirmEnvelope name="envelope" />
              <Text fz="0.95rem" ta="center" size="md">
                You have successfully canceled your GlobalLink WEB subscription.
                You’ll receive a confirmation email for your records. Your
                account will remain active until the next renewal date.
              </Text>
              <Text fz="0.95rem" ta="center" size="md">
                If you don't receive a confirmation email after 24 hours,
                contact us for support at: support@globalinkgo.com.
              </Text>
              <Link to={`/${projectId}/dashboard`}>
                <Button>Back to Dashboard</Button>
              </Link>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    );
  }

  return <Navigate to={`/${projectId}/dashboard`} />;
};

export default CancellationConfirmationPage;
