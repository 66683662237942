import React from 'react';
import { Box, Flex, Select, Text } from '@mantine/core';
import TargetLanguagesSelector from './TargetLanguagesSelector';
import { LanguageCodesStore, useLanguageCodesStore } from 'store';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { FaChevronDown } from 'react-icons/fa6';
import { getAvailableLanguages } from 'helpers';
import classes from './TranslatedLanguages.module.css';

export const TranslatedLanguages = () => {
  const { language_codes } = useLanguageCodesStore(
    (state: LanguageCodesStore) => state
  );

  const {
    selectedTargetLangCodes,
    selectedSourceLangCode,
    setSelectedSourceLangCode,
  } = useSetupStepsContext();

  const availableSourceLanguages = getAvailableLanguages(
    'source',
    language_codes,
    {
      sourceLangCode: selectedSourceLangCode || '',
      targetLangCodes: selectedTargetLangCodes,
    }
  );
  const availableTargetLanguages = getAvailableLanguages(
    'targets',
    language_codes,
    {
      sourceLangCode: selectedSourceLangCode || '',
      targetLangCodes: selectedTargetLangCodes,
    }
  );

  return (
    <Flex w="100%" direction="column" rowGap="1.5rem">
      <Flex w="100%" p={0} pos="relative" direction="column" rowGap="1rem">
        <Box>
          <Text fw={600} fz="1rem">
            SOURCE LANGUAGE
          </Text>
          <Text mt="0.5rem" fw={400} fz="1rem" c="text.9">
            Confirm the original language of your website.
          </Text>
        </Box>
        <Select
          width="100%"
          maw="25rem"
          size="md"
          allowDeselect={false}
          withCheckIcon={false}
          classNames={{
            dropdown: classes.selectDropdown,
            input: classes.selectSourceInput,
            wrapper: classes.selectSourceWrapper,
          }}
          rightSection={<FaChevronDown size={18} color="#CED4DA" />}
          onChange={setSelectedSourceLangCode}
          value={selectedSourceLangCode}
          data={availableSourceLanguages}
        />
      </Flex>
      <Flex w="100%" p={0} pos="relative" direction="column" rowGap="1rem">
        <Box>
          <Text fw={600} fz="1rem">
            TRANSLATED LANGUAGE(S)
          </Text>
          <Text mt="0.5rem" fw={400} fz="1rem" c="text.9">
            Type to add at least one translated language
          </Text>
        </Box>
        <TargetLanguagesSelector targetLanguages={availableTargetLanguages} />
      </Flex>
    </Flex>
  );
};
