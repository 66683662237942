import { Card, Flex, Text } from '@mantine/core';
import { StatusCircleIcon, OpenContext } from 'ui';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { HiPencil } from 'react-icons/hi';
import { getMTEngine, desktopRC, encodeKey } from 'helpers';
import { useGLGOParams } from 'hooks';

const Column = ({ title, value }: { title: string; value: string }) => (
  <Flex direction={'column'} align={'center'} miw={100}>
    <Text c="text2.5" fz="0.75rem" fw={700}>
      {title}
    </Text>
    <Text fz="1rem" fw={500}>
      {value}
    </Text>
  </Flex>
);

type Props = {
  translation: TranslationKey;
  handleSelection: (tKey: string) => void;
};
export const TranslationCard = ({ translation, handleSelection }: Props) => {
  const {
    target_lang_code,
    target_lang_name = '',
    source_lang_name = '',
    is_live = false,
    num_segment_translations = 0,
    mt_api_code = '',
    translation_key = '',
  } = translation || {};

  const sourceLanguage =
    source_lang_name
      ?.substring(0, source_lang_name.indexOf('('))
      .slice(0, -1) || 'English';
  const mtEngine = getMTEngine(mt_api_code);
  const { projectId } = useGLGOParams();

  return (
    <Card p={0}>
      <Flex
        wrap={'wrap'}
        mih={105}
        align="center"
        px={{ base: 20, md: 40 }}
        py={10}
      >
        <Flex
          direction="row"
          align="center"
          gap={20}
          miw={'min(450px, 100%)'}
          flex={'1 0 auto'}
        >
          <StatusCircleIcon status={is_live ? 'live' : 'private'} />
          <Text fw={500}>{sourceLanguage}</Text>
          <MdOutlineArrowForwardIos size={20} color="#000" />
          <Text style={{ whiteSpace: 'pre-wrap' }} fw={500}>
            {target_lang_name || target_lang_code}
          </Text>
        </Flex>
        <Flex gap={30} justify={'space-between'} flex={1}>
          <Column title="MT ENGINE" value={mtEngine} />
          <Column
            title="# OF SEGMENTS"
            value={new Intl.NumberFormat().format(num_segment_translations)}
          />
          <Flex align={'center'} justify={'center'} gap={8}>
            {num_segment_translations ? (
              <Flex
                onClick={() => {
                  handleSelection(translation_key);
                }}
              >
                <HiPencil className="cardActionIcon" />
              </Flex>
            ) : (
              <div style={{ width: 20 }} />
            )}
            <OpenContext
              path={`/${projectId}/${desktopRC.TRANSLATIONS}?t=${encodeKey(translation_key)}`}
            />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default TranslationCard;
